import styled from "styled-components";
import { useSelector } from "react-redux";
import { IoLocationOutline } from "react-icons/io5";

const Name = styled.div`
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: ${({ greyed, color }) => (greyed ? "#777777" : color)};
`;

const Headline = styled.div`
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  color: ${({ greyed, color }) => (greyed ? "#777777" : color)};
`;

const Location = styled.div`
  text-align: center;
  font-size: 13px;
  color: ${({ greyed, color }) => (greyed ? "#777777" : color)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Bio = styled.div`
  text-align: center;
  font-size: 13px;
  color: ${({ greyed, color }) => (greyed ? "#777777" : color)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const HeaderWrapper = styled.div`
  //position: sticky;
  //top: -80px;
  //background-color: #f5f6fa;
  //z-index: 1;
`;

const ProfileHeader = ({ info: { name, headline, location, bio } }) => {
  const settings = useSelector((state) => state.profile.settings);

  return (
    <HeaderWrapper>
      <Name greyed={!name} color={settings.mainColor}>
        {name || "your name"}
      </Name>
      <Headline greyed={!headline} color={settings.textColor}>
        {headline || "a headline"}
      </Headline>
      <Location greyed={!location} color={settings.textColor}>
        {/*<IoLocationOutline style={{ fontSize: 18 }} />{" "}*/}
        {location || "your location"}
      </Location>
      {bio && (
        <Bio greyed={!bio} color={settings.textColor}>
          {bio}
        </Bio>
      )}
    </HeaderWrapper>
  );
};

export default ProfileHeader;
