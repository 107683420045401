import styled from "styled-components";
import { colors, gradients, mainColors } from "../lib/constants";
import Button from "./layout/Button";
import { useState } from "react";
import Tabs from "./layout/Tabs";
import { Separator } from "./layout/Other";
import { backgrounds, backgroundUrl } from "../lib/backgrounds";

const ColorRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media only screen and (max-width: 900px) {
    justify-content: center;
  }
`;

const Color = styled.div`
  height: 24px;
  width: 24px;
  margin: 4px;
  cursor: pointer;
  border-radius: 12px;
  background-color: ${({ color }) => color};
  border: solid 1px #cccccc;
  outline: ${({ selected }) =>
    selected ? "solid 2px #000000" : "solid 2px #ffffff00"};
`;

const Gradient = styled.div`
  height: 24px;
  width: 48px;
  margin: 4px;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px #cccccc;
  outline: ${({ selected }) =>
    selected ? "solid 2px #000000" : "solid 2px #ffffff00"};
  ${({ color }) => color};
`;

const Image = styled.div`
  height: 48px;
  width: 48px;
  margin: 4px;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px #cccccc;
  outline: ${({ selected }) =>
    selected ? "solid 2px #000000" : "solid 2px #ffffff00"};
  ${({ color }) => color};
  background-image: url(${backgroundUrl}/${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
`;

const getTabs = (withGradient, withImages) => {
  const tabs = [{ label: "solid", value: "solid" }];
  if (withGradient) {
    tabs.push({ label: "gradient", value: "gradient" });
  }
  if (withImages) {
    tabs.push({ label: "images", value: "images" });
  }

  return tabs;
};

const ColorPicker = ({ color, onSelect, withGradient, withImages }) => {
  const [showMore, setShowMore] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    colors.indexOf(color) > -1 ? 0 : color?.substr(0, 6) === "image:" ? 2 : 1
  );

  return (
    <>
      {(withGradient || withImages) && (
        <>
          <Tabs
            selected={selectedTab}
            tabs={getTabs(withGradient, withImages)}
            onSelect={setSelectedTab}
            small
          />
          <Separator size={16} />
        </>
      )}
      {selectedTab === 0 && (
        <>
          <ColorRow>
            {(showMore ? colors : mainColors).map((c) => (
              <Color
                key={c}
                color={c}
                onClick={() => onSelect(c)}
                selected={c === color}
              />
            ))}
            <Button
              status="info"
              small
              onClick={() => setShowMore(!showMore)}
              style={{ borderRadius: 24, width: 32 }}
            >
              {showMore ? ".." : "..."}
            </Button>
          </ColorRow>
        </>
      )}
      {selectedTab === 1 && (
        <>
          <ColorRow>
            {(showMore ? gradients : gradients.slice(0, 5)).map((c) => (
              <Gradient
                key={c}
                color={c}
                onClick={() => onSelect(c)}
                selected={c === color}
              />
            ))}
            <Button
              status="info"
              small
              onClick={() => setShowMore(!showMore)}
              style={{ borderRadius: 24, height: 32, width: 32 }}
            >
              {showMore ? ".." : "..."}
            </Button>
          </ColorRow>
        </>
      )}

      {selectedTab === 2 && (
        <>
          <ColorRow>
            {(showMore ? backgrounds : backgrounds.slice(0, 5)).map((b) => (
              <Image
                key={"background-" + b.id}
                imageUrl={b.urlSmall}
                onClick={() => onSelect("image:" + b.id)}
                selected={"image:" + b.id === color}
              />
            ))}
            <Button
              status="info"
              small
              onClick={() => setShowMore(!showMore)}
              style={{
                borderRadius: 24,
                height: 32,
                width: 32,
                margin: "auto 0",
              }}
            >
              {showMore ? ".." : "..."}
            </Button>
          </ColorRow>
        </>
      )}
    </>
  );
};

export default ColorPicker;
