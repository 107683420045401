import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  idToken: null,
  name: null,
  email: null,
  subscription: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.idToken = action.payload;
    },
    setTokenAndData: (state, action) => {
      state.idToken = action.payload.token;
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    clearToken: (state) => {
      state.idToken = null;
      state.name = null;
      state.email = null;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
  },
});

export const { setToken, setTokenAndData, clearToken, setSubscription } =
  userSlice.actions;

export default userSlice.reducer;
