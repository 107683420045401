import React, { useId, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "./constants";

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-radius: 16px;
  border-color: ${colors.gray};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 16px 0 0;
  transition: border 0.15s ease-in;
  background-color: #ffffff;

  ${({ isFocused }) =>
    isFocused ? `border-color: ${colors.primaryHover};` : ""}
`;

const StyledInput = styled.input`
  border: 0;
  height: 44px;
  width: 100%;
  padding: ${({ hasPrefix }) => (hasPrefix ? "0 16px 0 0" : "0")};
  margin: ${({ hasPrefix }) => (hasPrefix ? "0" : "0 16px")};
  transition: border 0.15s ease-in;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: ${colors.primaryHover};
  }
`;

const InputLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  //background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) calc(50% - 2px),
    rgba(255, 255, 255, 255) calc(50% - 2px),
    rgba(255, 255, 255, 255) 100%
  );
  transform: translateX(16px) translateY(-50%);
  padding: 0 8px;
  font-size: 17px;
  height: 24px;
  font-weight: bold;
  border-radius: 16px;
`;

const InlineError = styled.div`
  color: ${colors.danger};
  font-size: 12px;
  font-weight: bold;
  padding-left: 8px;
  padding-top: 4px;
`;

const Prefix = styled.div`
  color: #777777;
  padding: 0 0 0 16px;
  font-size: 14px;
  height: 44px;
  line-height: 44px;
`;

const Required = styled.span`
  color: #e74c3c;

  :after {
    content: "*";
  }
`;

const Input = ({
  name,
  label,
  placeholder,
  inlineError,
  prefix,
  type,
  required,
  ...props
}) => {
  const inputId = useId();
  const ref = useRef();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <InputWrapper isFocused={isFocused}>
        <InputLabel htmlFor={props.id || inputId}>
          {label} {required && <Required />}
        </InputLabel>
        {prefix && (
          <Prefix onClick={() => ref.current.focus()}>{prefix}</Prefix>
        )}
        <StyledInput
          id={props.id || inputId}
          name={name}
          type={type}
          placeholder={placeholder}
          {...props}
          hasPrefix={!!prefix}
          ref={ref}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </InputWrapper>
      {inlineError && <InlineError>{inlineError}</InlineError>}
    </>
  );
};

export default Input;
