import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  FaBehance,
  FaDribbble,
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaLink,
  FaLinkedin,
} from "react-icons/fa";
import { linkList } from "../screens/links/linkList";
import Tooltip from "./layout/Tooltip";

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 16px;
  justify-content: center;
`;

const Link = styled.a`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: solid 1px #bdc3c777;
  background-color: #ecf0f177;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s ease-in;

  &:hover {
    background-color: #bdc3c777;
  }
`;

const SocialLinks = ({ links = [] }) => {
  const settings = useSelector((state) => state.profile.settings);

  return (
    <LinksWrapper>
      {links.map((l) => (
        <Tooltip text={l.type} key={`social-link-${l.url}`}>
          <Link color={settings.buttonTextColor}>{linkList[l.type]?.icon}</Link>
        </Tooltip>
      ))}
    </LinksWrapper>
  );
};

export default SocialLinks;
