export const mainColors = [
  "#ffffff",
  "#000000",
  "#fad390",
  "#b71540",
  "#82ccdd",
  "#b8e994",
];

export const colors = [
  "#ffffff",
  "#000000",
  "#fad390",
  "#f6b93b",
  "#fa983a",
  "#e58e26",
  "#f8c291",
  "#e55039",
  "#eb2f06",
  "#b71540",
  "#6a89cc",
  "#4a69bd",
  "#1e3799",
  "#0c2461",
  "#82ccdd",
  "#60a3bc",
  "#3c6382",
  "#0a3d62",
  "#b8e994",
  "#78e08f",
  "#38ada9",
  "#079992",
];

export const chartColors = [
  "#e58e26",
  "#eb2f06",
  "#b71540",
  "#1e3799",
  "#0c2461",
  "#3c6382",
  "#0a3d62",
  "#38ada9",
  "#079992",
];

export const gradients = [
  "background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);",
  "background-image: linear-gradient(-45deg, #FFC796 0%, #FF6B95 100%);",
  "background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);",
  "background-image: linear-gradient(to top, #b3ffab 0%, #12fff7 100%);",
  "background-image: linear-gradient(to top, #d5dee7 0%, #ffafbd 0%, #c9ffbf 100%);",
  "background-image: linear-gradient(to top, #bdc2e8 0%, #bdc2e8 1%, #e6dee9 100%);",
  "background-image: linear-gradient(to top, #e6b980 0%, #eacda3 100%);",
  "background-image: linear-gradient(to top, #209cff 0%, #68e0cf 100%);",
  "background-color: #CDDCDC;\n" +
    " background-image: radial-gradient(at 50% 100%, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), linear-gradient(to bottom, rgba(255,255,255,0.25) 0%, rgba(0,0,0,0.25) 100%);\n" +
    " background-blend-mode: screen, overlay;",
  "background-image: linear-gradient(45deg, #ee9ca7 0%, #ffdde1 100%);",
  "background-image: linear-gradient(60deg, #64b3f4 0%, #c2e59c 100%);",
  "background-image: linear-gradient(to top, #e8198b 0%, #c7eafd 100%);",
  "background-image: radial-gradient(73% 147%, #EADFDF 59%, #ECE2DF 100%), radial-gradient(91% 146%, rgba(255,255,255,0.50) 47%, rgba(0,0,0,0.50) 100%);\n" +
    " background-blend-mode: screen;",
  "background-image: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);",
  "background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%);",
  "background-image: linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%);",
  "background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);",
  "background-image: linear-gradient(to top, #c71d6f 0%, #d09693 100%);",
  "background-image: linear-gradient(to right, #a8caba 0%, #5d4157 100%);",
  "background-image: linear-gradient(60deg, #96deda 0%, #50c9c3 100%);",
  "background-image: linear-gradient(-20deg, #dcb0ed 0%, #99c99c 100%);",
  "background-image: linear-gradient(60deg, #abecd6 0%, #fbed96 100%);",
  "background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);",
  "background-image: linear-gradient(to top, #5f72bd 0%, #9b23ea 100%);",
  "background-image: linear-gradient(-20deg, #d558c8 0%, #24d292 100%);",
  "background-image: linear-gradient(45deg, #8baaaa 0%, #ae8b9c 100%);",
  "background-image: linear-gradient(to right, #434343 0%, black 100%);",
  "background: #C9CCD3;\n" +
    " background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);\n" +
    " background-blend-mode: lighten;",
  "background-image: linear-gradient(to top, #d9afd9 0%, #97d9e1 100%);",
  "background-image: linear-gradient(to top, #88d3ce 0%, #6e45e2 100%);",
  "background-image: linear-gradient(to top, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);",
];

export const fonts = [
  {
    label: "Sarabun (default)",
    value: "sarabun",
  },
  {
    label: "Roboto",
    value: "Roboto",
  },
  {
    label: "Open Sans",
    value: "'Open Sans'",
  },
  {
    label: "Trispace",
    value: "trispace",
  },
  {
    label: "Raleway",
    value: "raleway",
  },
  {
    label: "PT Sans",
    value: "'PT Sans'",
  },
  {
    label: "Merriweather",
    value: "Merriweather",
  },
  {
    label: "Barlow",
    value: "barlow",
  },
].sort((a, b) => a.label.localeCompare(b.label));
