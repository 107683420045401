import axios from "axios";
import { API_URL } from "../api";
import { getToken } from "../session";

export const bGetAnalytics = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/analytics`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
