import React from "react";
import styled from "styled-components";
import { colors } from "./constants";

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  
  ${({ scrollOverflow }) => scrollOverflow ? `
  overflow-x: auto;
  `: ''}

  @media only screen and (max-width: 900px) {
    gap: 8px;
  }
`;

const Tab = styled.div`
  padding: ${({ small }) => (small ? "4px 8px" : "8px 16px")};
  cursor: pointer;
  background-color: ${({ selected }) =>
    selected ? colors.primary : colors.lightGray};
  color: ${({ selected }) => (selected ? "#ffffff" : "#000000")};
  border-radius: 32px;
  transition: all 0.15s ease-in;
  font-size: ${({ small }) => (small ? "13px" : "17px")};
  font-weight: bold;

  &:hover {
    filter: brightness(1.1);
  }

  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

export const StickyTabsWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: #f5f6fa;
  z-index: 11;
  padding: 10px 0;
`;

const Tabs = ({ tabs, selected, onSelect, small, scrollOverflow, ...props }) => {
  return (
    <TabsWrapper {...props} scrollOverflow={scrollOverflow}>
      {tabs?.map((t, key) => (
        <Tab
          key={t.label}
          onClick={() => onSelect(key)}
          selected={selected === key}
          small={small}
        >
          {t.label}
        </Tab>
      ))}
    </TabsWrapper>
  );
};

export default Tabs;
