import axios from "axios";
import { API_URL } from "../api";
import { getToken } from "../session";

export const bCreateSession = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/subscription/create-session`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          },
        }
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const bCreatePortalSession = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/subscription/create-portal-session`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          },
        }
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
