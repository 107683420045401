let i = 0;
export const backgrounds = [
  {
    id: i++,
    urlLarge: "abyan-athif-BCx6t5pJwVw-unsplash.jpg",
    copyright: "abyan-athif-BCx6t5pJwVw-unsplash.jpg",
    urlSmall: "sm-abyan-athif-BCx6t5pJwVw-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "alessandra-caretto-cAY9X4rPG3g-unsplash.jpg",
    urlLarge: "alessandra-caretto-cAY9X4rPG3g-unsplash.jpg",
    urlSmall: "sm-alessandra-caretto-cAY9X4rPG3g-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "alexandre-lallemand-JE8vJ-sk1K4-unsplash.jpg",
    urlLarge: "alexandre-lallemand-JE8vJ-sk1K4-unsplash.jpg",
    urlSmall: "sm-alexandre-lallemand-JE8vJ-sk1K4-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "amy-shamblen-61rFb1qnu1k-unsplash.jpg",
    urlLarge: "amy-shamblen-61rFb1qnu1k-unsplash.jpg",
    urlSmall: "sm-amy-shamblen-61rFb1qnu1k-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "andrej-lisakov-V2OyJtFqEtY-unsplash.jpg",
    urlLarge: "andrej-lisakov-V2OyJtFqEtY-unsplash.jpg",
    urlSmall: "sm-andrej-lisakov-V2OyJtFqEtY-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "andrew-ridley-jR4Zf-riEjI-unsplash.jpg",
    urlLarge: "andrew-ridley-jR4Zf-riEjI-unsplash.jpg",
    urlSmall: "sm-andrew-ridley-jR4Zf-riEjI-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "bryan-garces-zwo-0E7l1ZI-unsplash.jpg",
    urlLarge: "bryan-garces-zwo-0E7l1ZI-unsplash.jpg",
    urlSmall: "sm-bryan-garces-zwo-0E7l1ZI-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "camille-couvez-H5PnIYI_1I0-unsplash.jpg",
    urlLarge: "camille-couvez-H5PnIYI_1I0-unsplash.jpg",
    urlSmall: "sm-camille-couvez-H5PnIYI_1I0-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "david-pisnoy-46juD4zY1XA-unsplash.jpg",
    urlLarge: "david-pisnoy-46juD4zY1XA-unsplash.jpg",
    urlSmall: "sm-david-pisnoy-46juD4zY1XA-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "enq-1998-9hf9kJoWHQs-unsplash.jpg",
    urlLarge: "enq-1998-9hf9kJoWHQs-unsplash.jpg",
    urlSmall: "sm-enq-1998-9hf9kJoWHQs-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "girl-with-red-hat-h-6w7HwZRdg-unsplash.jpg",
    urlLarge: "girl-with-red-hat-h-6w7HwZRdg-unsplash.jpg",
    urlSmall: "sm-girl-with-red-hat-h-6w7HwZRdg-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "gradienta-7brhZmwXn08-unsplash.jpg",
    urlLarge: "gradienta-7brhZmwXn08-unsplash.jpg",
    urlSmall: "sm-gradienta-7brhZmwXn08-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "gradienta-el5ZXkPvd0o-unsplash.jpg",
    urlLarge: "gradienta-el5ZXkPvd0o-unsplash.jpg",
    urlSmall: "sm-gradienta-el5ZXkPvd0o-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "gradienta-m_7p45JfXQo-unsplash.jpg",
    urlLarge: "gradienta-m_7p45JfXQo-unsplash.jpg",
    urlSmall: "sm-gradienta-m_7p45JfXQo-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "ilnur-kalimullin-kP1AxmCyEXM-unsplash.jpg",
    urlLarge: "ilnur-kalimullin-kP1AxmCyEXM-unsplash.jpg",
    urlSmall: "sm-ilnur-kalimullin-kP1AxmCyEXM-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "janita-sumeiko-ZK1WQDMQvik-unsplash.jpg",
    urlLarge: "janita-sumeiko-ZK1WQDMQvik-unsplash.jpg",
    urlSmall: "sm-janita-sumeiko-ZK1WQDMQvik-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "jason-leung-Xaanw0s0pMk-unsplash.jpg",
    urlLarge: "jason-leung-Xaanw0s0pMk-unsplash.jpg",
    urlSmall: "sm-jason-leung-Xaanw0s0pMk-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "jason-leung-wHddViTmSvA-unsplash.jpg",
    urlLarge: "jason-leung-wHddViTmSvA-unsplash.jpg",
    urlSmall: "sm-jason-leung-wHddViTmSvA-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "jason-leung-xnFOY9SfcgI-unsplash.jpg",
    urlLarge: "jason-leung-xnFOY9SfcgI-unsplash.jpg",
    urlSmall: "sm-jason-leung-xnFOY9SfcgI-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "jess-bailey-Bg14l3hSAsA-unsplash.jpg",
    urlLarge: "jess-bailey-Bg14l3hSAsA-unsplash.jpg",
    urlSmall: "sm-jess-bailey-Bg14l3hSAsA-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "joshua-coleman-Cj8h7-b47ko-unsplash.jpg",
    urlLarge: "joshua-coleman-Cj8h7-b47ko-unsplash.jpg",
    urlSmall: "sm-joshua-coleman-Cj8h7-b47ko-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "kirill-tonkikh-25uFn92ouKI-unsplash.jpg",
    urlLarge: "kirill-tonkikh-25uFn92ouKI-unsplash.jpg",
    urlSmall: "sm-kirill-tonkikh-25uFn92ouKI-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "kym-mackinnon-rwB5zU55s8o-unsplash.jpg",
    urlLarge: "kym-mackinnon-rwB5zU55s8o-unsplash.jpg",
    urlSmall: "sm-kym-mackinnon-rwB5zU55s8o-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "martin-sanchez-DWCKcc4gtE0-unsplash.jpg",
    urlLarge: "martin-sanchez-DWCKcc4gtE0-unsplash.jpg",
    urlSmall: "sm-martin-sanchez-DWCKcc4gtE0-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "martin-sanchez-eMonA5Lc6Gk-unsplash.jpg",
    urlLarge: "martin-sanchez-eMonA5Lc6Gk-unsplash.jpg",
    urlSmall: "sm-martin-sanchez-eMonA5Lc6Gk-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "meiying-ng-OrwkD-iWgqg-unsplash.jpg",
    urlLarge: "meiying-ng-OrwkD-iWgqg-unsplash.jpg",
    urlSmall: "sm-meiying-ng-OrwkD-iWgqg-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "mike-yukhtenko-a2kD4b0KK4s-unsplash.jpg",
    urlLarge: "mike-yukhtenko-a2kD4b0KK4s-unsplash.jpg",
    urlSmall: "sm-mike-yukhtenko-a2kD4b0KK4s-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "nick-fewings-PTw_2g55U8w-unsplash.jpg",
    urlLarge: "nick-fewings-PTw_2g55U8w-unsplash.jpg",
    urlSmall: "sm-nick-fewings-PTw_2g55U8w-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "pawel-czerwinski-OG44d93iNJk-unsplash.jpg",
    urlLarge: "pawel-czerwinski-OG44d93iNJk-unsplash.jpg",
    urlSmall: "sm-pawel-czerwinski-OG44d93iNJk-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "photo-boards-KZNTEn2r6tw-unsplash.jpg",
    urlLarge: "photo-boards-KZNTEn2r6tw-unsplash.jpg",
    urlSmall: "sm-photo-boards-KZNTEn2r6tw-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "richard-horvath-cPccYbPrF-A-unsplash.jpg",
    urlLarge: "richard-horvath-cPccYbPrF-A-unsplash.jpg",
    urlSmall: "sm-richard-horvath-cPccYbPrF-A-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "simon-berger-twukN12EN7c-unsplash.jpg",
    urlLarge: "simon-berger-twukN12EN7c-unsplash.jpg",
    urlSmall: "sm-simon-berger-twukN12EN7c-unsplash.jpg",
  },
  {
    id: i++,
    copyright: "szabo-viktor-28ZbKOWiZfs-unsplash.jpg",
    urlLarge: "szabo-viktor-28ZbKOWiZfs-unsplash.jpg",
    urlSmall: "sm-szabo-viktor-28ZbKOWiZfs-unsplash.jpg",
  },
];

export const backgroundUrl = "https://assets.presenceof.me/backgrounds";
