import styled from "styled-components";
import { Separator } from "../../components/layout/Other";
import { useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../../components/Avatar";
import ProfileHeader from "../../components/ProfileHeader";
import Links from "../../components/Links";
import SocialLinks from "../../components/SocialLinks";
import { backgrounds, backgroundUrl } from "../../lib/backgrounds";
import { S3_BUCKET_AVATARS_URL } from "../../lib/urls";
import { convertDataToHtml } from "../../lib/editorjs-parser";
import Button from "../../components/layout/Button";
import { FaArrowLeft } from "react-icons/fa";

const BigWrapper = styled.div`
  padding: 20px;
  margin: 16px;
  border-radius: 16px;
  ${({ bgColor }) => {
    if (bgColor.substr(0, 1) === "#") {
      return `background-color: ${bgColor}`;
    }

    if (bgColor.substr(0, 6) === "image:") {
      const bgId = parseInt(bgColor.substr(6), 10);
      return `background-image: url("${backgroundUrl}/${backgrounds[bgId]?.urlLarge}");
      background-size: cover;
      background-position: center;`;
    }

    return bgColor;
  }};
  transition: all 0.15s ease-in;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: ${({ topPadding }) => (topPadding ? "80px" : "20px")};
  ${({ topPadding }) => (topPadding ? "overflow: auto;" : "")}

  ${({ font }) =>
    font
      ? `* {
      font-family: ${font} !important;
    }`
      : ""}
`;

const Wrapper = styled.div`
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 1000px;

  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  position: relative;
  min-height: 100px;
  transition: all 0.15s ease-in;
  z-index: 2;
  ${({ bgColor }) =>
    bgColor.substr(0, 1) === "#" ? `background-color: ${bgColor}` : bgColor};
`;

const FloatingAvatar = styled.div`
  position: absolute;
  top: -36px;
  ${({ avatarPosition }) =>
    avatarPosition === "left" ? "left: 20px;" : "left: calc(100% - 90px);"};

  transition: all 0.3s ease-in;
`;

const BackButton = styled.button`
  padding: 8px 4px;
  border-radius: 2px;
  border: solid 1px #bdc3c777;
  background-color: #ecf0f177;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s ease-in;
  width: 100%;
  margin-bottom: 24px;

  &:hover {
    background-color: #bdc3c777;
  }
`;

const Preview = ({ style, topPadding }) => {
  const profile = useSelector((state) => state.profile);
  const [selectedPage, setSelectedPage] = useState(null);

  return (
    <BigWrapper
      bgColor={profile.settings.mainBgColor}
      style={style}
      topPadding={topPadding}
      font={profile.settings.font}
    >
      <Wrapper bgColor={profile.settings.bgColor}>
        {profile.settings.avatarPosition !== "hidden" && (
          <FloatingAvatar avatarPosition={profile.settings.avatarPosition}>
            <Avatar
              name={profile.info.name}
              size={72}
              background={profile.settings.mainBgColor}
              photo={
                profile.settings.avatarUrl
                  ? S3_BUCKET_AVATARS_URL + profile.settings.avatarUrl
                  : null
              }
              // bgColor={profile.settings.mainBgColor}
              // fgColor={profile.settings.bgColor}
            />
          </FloatingAvatar>
        )}
        <ProfileHeader info={profile.info} />
        {profile.headlineLinks.length > 0 && (
          <SocialLinks links={profile.headlineLinks} />
        )}
        {selectedPage && (
          <>
            <BackButton
              onClick={() => setSelectedPage(null)}
              color={profile.settings.buttonTextColor}
            >
              <FaArrowLeft />
            </BackButton>
            <div
              dangerouslySetInnerHTML={{
                __html: convertDataToHtml(selectedPage?.content),
              }}
            />
          </>
        )}
        {!selectedPage && (
          <Links
            links={profile.links}
            onSelectPage={(pId) =>
              setSelectedPage(profile.pages?.find((p) => p.id === pId))
            }
          />
        )}
      </Wrapper>
    </BigWrapper>
  );
};

export default Preview;
