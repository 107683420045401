import { Centered } from "../../components/layout/Other";
import styled from "styled-components";

const Wrapper = styled.div`
  color: #ffffff;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  padding: 48px;
  background: linear-gradient(90deg, #00ffff, #25bfff, #4b7fff, #703fff, #9600ff);
  border-radius: 16px;
  text-shadow: 0 0 3px #000000;
`;

const Success = () => {
  return (
    <>
      <Centered>
        <Wrapper>
          <TextWrapper>
            <h1>
              thank you for purchasing the presenceof.me premium subscription!
            </h1>
          </TextWrapper>
        </Wrapper>
      </Centered>
    </>
  );
};

export default Success;
