import styled from "styled-components";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./toastify.scss";
import Header from "./components/layout/Header";
import Button from "./components/layout/Button";
import { AppContainer, Container, Separator } from "./components/layout/Other";
import Row from "./components/layout/Row";
import Personalize from "./screens/personalize/Personalize";
import Analytics from "./screens/analytics/Analytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import LoadingBar from "./components/layout/LoadingBar";
import Login from "./screens/login/Login";
import { useEffect, useState } from "react";
import { setToken } from "./backend/session";
import Footer from "./components/layout/Footer";
import { bGetSubscription, bRetrieveProfile } from "./backend/profile/profile";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "./redux/features/profile/profileSlice";
import Col from "./components/layout/Col";
import PrivacyPolicy from "./screens/other/PrivacyPolicy";
import TermsOfUse from "./screens/other/TermsOfUse";
import {
  FaChartPie,
  FaExternalLinkAlt,
  FaPen,
  FaPowerOff,
} from "react-icons/fa";
import Tooltip from "./components/layout/Tooltip";
import { bCreateSession } from "./backend/profile/subscription";
import Success from "./screens/checkout/Failed";
import { setSubscription } from "./redux/features/user/userSlice";
import Subscription from "./screens/subscription/Subscription";
import { enableSubscriptions } from "./lib/flags";
import NotFound from "./screens/other/NotFound";

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: blueviolet;
  padding: 4px;
  cursor: pointer;

  &:hover {
    background-color: blueviolet;
    color: #ffffff;
  }
`;

const RoundLogo = styled.div`
  font-size: 18px;
  font-weight: bold;
  background-color: blueviolet;
  color: #ffffff;
  padding: 4px 12px;
  cursor: pointer;
  line-height: 24px;
`;

const FooterRow = styled(Row)`
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

/*
const Card = styled.div`
  width: 100vw;
  height: 400px;
  background-color: red;
`;
const [a, setA] = useState({});

useEffect(() => {
  window.addEventListener(
    "deviceorientation",
    (e) => {
      alert('here')
      setA(e);
    },
    true
  );
}, []);
*/

function App() {
  const location = useLocation();

  const [user, loading, error] = useAuthState(auth);

  const [fetchingProfile, setFetchingProfile] = useState(false);
  const dispatch = useDispatch();

  const userAuthData = useSelector((state) => state.user);
  const info = useSelector((state) => state.profile.info);
  const subscription = useSelector((state) => state.user.subscription);

  // const signOut
  // console.log(user, loading, error);

  useEffect(() => {
    const run = async () => {
      const token = await user.getIdToken();
      setToken(token);

      setFetchingProfile(true);
      const { profile } = await bRetrieveProfile(
        userAuthData.name,
        userAuthData.email,
        token
      );

      setFetchingProfile(false);
      dispatch(setProfile(profile));

      const subscriptionData = await bGetSubscription(token);
      dispatch(setSubscription(subscriptionData));
    };

    if (user) {
      run();
    }
  }, [user]);

  if (!user) {
    return <Login isLoading={loading} />;
  }

  const subscribe = () => {
    bCreateSession().then((data) => {
      if (data && data.url) {
        window.location = data.url;
      }
    });
  };

  return (
    <>
      <AppContainer style={{ minHeight: "calc(100vh - 64px)" }}>
        {loading && <LoadingBar />}
        <Header
          logo={
            <Link to="/" style={{ textDecoration: "none" }}>
              <Logo className="hide-mobile">presenceof.me</Logo>
              <RoundLogo className="show-mobile">p</RoundLogo>
            </Link>
          }
          transparent
          options={
            <>
              <Row gap={8} className="hide-mobile">
                <Link to={"/"}>
                  <Button outline noBorder={location.pathname !== "/"}>
                    customize
                  </Button>
                </Link>
                <Link to={"/analytics"}>
                  <Button outline noBorder={location.pathname !== "/analytics"}>
                    analytics
                  </Button>
                </Link>

                {enableSubscriptions && subscription && (
                  <Link to={"/subscription"}>
                    <Button
                      outline
                      noBorder={location.pathname !== "/subscription"}
                    >
                      your subscription
                    </Button>
                  </Link>
                )}

                {info?.prettyUrl && (
                  <a
                    href={`https://www.presenceof.me/${info.prettyUrl}?no-track=true`}
                    target="_blank"
                  >
                    <Button outline noBorder>
                      live preview <FaExternalLinkAlt />
                    </Button>
                  </a>
                )}
                {!info?.prettyUrl && (
                  <Tooltip
                    text={
                      <span>
                        you have to choose a custom url in the{" "}
                        <strong>share</strong> tab first
                      </span>
                    }
                  >
                    <Button outline noBorder disabled>
                      live preview <FaExternalLinkAlt />
                    </Button>
                  </Tooltip>
                )}

                {enableSubscriptions && !subscription && (
                  <Link to="/subscription">
                    <Button outline status="danger">
                      upgrade to premium
                    </Button>
                  </Link>
                )}

                <Button status="danger" onClick={() => auth.signOut()}>
                  log out
                </Button>
              </Row>

              <Row gap={8} className="show-mobile">
                <Link to={"/"}>
                  <Button outline noBorder={location.pathname !== "/"}>
                    <FaPen />
                  </Button>
                </Link>
                <Link to={"/analytics"}>
                  <Button outline noBorder={location.pathname !== "/analytics"}>
                    <FaChartPie />
                  </Button>
                </Link>
                {info?.prettyUrl && (
                  <a
                    href={`https://www.presenceof.me/${info.prettyUrl}?no-track=true`}
                    target="_blank"
                  >
                    <Button outline noBorder>
                      <FaExternalLinkAlt />
                    </Button>
                  </a>
                )}
                {!info?.prettyUrl && (
                  <Tooltip
                    text={
                      <span>
                        you have to choose a custom url in the{" "}
                        <strong>share</strong> tab first
                      </span>
                    }
                  >
                    <Button outline noBorder disabled>
                      <FaExternalLinkAlt />
                    </Button>
                  </Tooltip>
                )}
                <Button status="danger" onClick={() => auth.signOut()}>
                  <FaPowerOff />
                </Button>
              </Row>
            </>
          }
        />
        {enableSubscriptions && !subscription && (
          <Row
            gap={8}
            className="show-mobile"
            style={{ width: "100%", padding: "0 16px" }}
          >
            <Col flex={1}>
              <Link to="/subscription">
                <Button outline status="danger" block>
                  upgrade to premium
                </Button>
              </Link>
            </Col>
          </Row>
        )}

        <Container>
          <Routes>
            <Route
              path="/"
              element={<Personalize loading={loading || fetchingProfile} />}
            />
            <Route
              path="/analytics"
              element={<Analytics loading={loading || fetchingProfile} />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/checkout/success" element={<Success />} />
            {enableSubscriptions && (
              <Route path="/subscription" element={<Subscription />} />
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      </AppContainer>

      <Separator size={32} />

      <Footer>
        <AppContainer>
          <FooterRow>
            <Col flex={8}>
              <FooterRow gap={16}>
                <Link to={"/privacy-policy"}>
                  <Button status="info" noBorder outline small>
                    privacy policy
                  </Button>
                </Link>
                <Link to={"/terms-of-use"}>
                  <Button status="info" noBorder outline small>
                    terms of use
                  </Button>
                </Link>
                <a
                  href="https://www.presenceof.me/refund-policy"
                  target="_blank"
                >
                  <Button status="info" noBorder outline small>
                    refund policy
                  </Button>
                </a>
                <a href="https://form.typeform.com/to/CtHXgBZV" target="_blank">
                  <Button status="info" noBorder outline small>
                    contact us
                  </Button>
                </a>
              </FooterRow>
            </Col>
            <Col flex={2}>
              <a
                href="https://www.linkedin.com/company/colorcode-romania"
                target="_blank"
              >
                <Button status="info" noBorder outline small>
                  Copyright &copy; Colorcode 2022.
                </Button>
              </a>
            </Col>
          </FooterRow>
        </AppContainer>
      </Footer>
    </>
  );
}

export default App;
