import React from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import styled, { createGlobalStyle } from "styled-components";
import { colors } from "./constants";
import Button from "./Button";
import {Separator} from "./Other";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;
const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 300ms ease-out;
  opacity: 0;
`;

const StyledModal = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 1002;
  min-width: 40vw;
  overflow: hidden;
  transition: opacity 300ms ease-out;
  opacity: 0;
  top: 100px;

  @media only screen and (max-width: 900px) {
    width: 98vw;
  }
`;

const ModalContent = styled.div`
  padding: 24px 24px;

  @media only screen and (max-width: 900px) {
    padding: 8px;
  }
`;

const ButtonsWrapper = styled.div`
  background-color: ${colors.lightGray};
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

const ModalTitle = styled.h2`
  margin: 0;
`;

const overlayTransitionStyles = {
  entering: { opacity: 1, display: "block" },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0, display: "none" },
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0, top: "-100vh" },
};

const Modal = ({
  visible,
  title,
  content,
  showCloseButton,
  showCancelButton,
  showActionButton,
  actionType,
  actionLabel,
  showOverlay,
  onClose,
  onAct,
  children,
  ...props
}) => {
  return (
    <Transition in={visible} timeout={300}>
      {(state) => (
        <>
          {showOverlay && (
            <>
              <Overlay
                onClick={onClose}
                style={{
                  ...overlayTransitionStyles[state],
                }}
              />
              {visible && <GlobalStyle />}
            </>
          )}
          <StyledModal
            {...props}
            style={{
              ...props.style,
              ...transitionStyles[state],
            }}
          >
            <ModalContent>
              <ModalTitle>{title}</ModalTitle>
              <Separator />
              {children}
            </ModalContent>
            {(showCloseButton || showCancelButton || showActionButton) && (
              <ButtonsWrapper>
                {showCloseButton && (
                  <Button onClick={onClose} status="info">
                    close
                  </Button>
                )}
                {showCancelButton && (
                  <Button onClick={onClose} status="info">
                    cancel
                  </Button>
                )}
                {showActionButton && (
                  <Button onClick={onAct} status={actionType}>
                    {actionLabel}
                  </Button>
                )}
              </ButtonsWrapper>
            )}
          </StyledModal>
        </>
      )}
    </Transition>
  );
};

export default Modal;
