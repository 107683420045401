import styled from "styled-components";
import {
  Centered,
  InfoBar,
  RoundGrayContainer,
  Separator,
} from "../../components/layout/Other";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSetting,
  setAllSettings,
} from "../../redux/features/profile/profileSlice";
import Row, { FlexRow } from "../../components/layout/Row";
import Modal from "../../components/layout/Modal";
import ColorPicker from "../../components/ColorPicker";
import Select from "../../components/layout/Select";
import Col from "../../components/layout/Col";
import Button from "../../components/layout/Button";
import { colors, fonts, gradients } from "../../lib/constants";
import { toast } from "react-toastify";
import { bUploadAvatar } from "../../backend/profile/profile";
import { FileUploader } from "react-drag-drop-files";
import { enableAvatarPictures } from "../../lib/flags";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { colors as appColors } from "../../components/layout/constants";

const UploadContainer = styled.div`
  height: 48px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-radius: 16px;
  border-color: #a5b1c2;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  min-width: 200px;
`;

const UploadContainerLabel = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  transform: translateX(16px) translateY(-50%);
  padding: 0 8px;
  font-size: 17px;
  height: 24px;
  font-weight: bold;
  border-radius: 16px;
`;

const RadiusSample = styled.div`
  border: solid ${({ selected }) => (selected ? "#ffffff" : appColors.primary)};
  width: 24px;
  height: 24px;
  border-width: 4px 0 0 4px;
  border-top-left-radius: ${({ radius }) => radius}px;
`;

export const getExtensionFromMimeType = (mimeType) => {
  switch (mimeType) {
    case "image/png":
      return ".png";
    case "image/jpg":
      return ".jpg";
    case "image/jpeg":
      return ".jpeg";
    default:
      return null;
  }
};

const buttonRadiuses = [0, 8, 16, 32];

const Customization = () => {
  const [showAutoGenerateStyling, setShowAutoGenerateStyling] = useState(false);
  const [tempAvatar, setTempAvatar] = useState(null);
  const [avatarError, setAvatarError] = useState(null);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);

  const settings = useSelector((state) => state.profile.settings);
  const dispatch = useDispatch();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!tempAvatar) {
      return;
    }

    setAvatarError(null);
  }, [tempAvatar]);

  const submitAvatar = () => {
    setAvatarError(null);
    setIsUploadingAvatar(true);
    bUploadAvatar(tempAvatar)
      .then(({ success }) => {
        setIsUploadingAvatar(false);
        if (success) {
          dispatch(
            setSetting({
              parameter: "avatarUrl",
              value:
                user.uid +
                getExtensionFromMimeType(tempAvatar.type) +
                "?cache=" +
                Math.floor(Math.random() * 9999999),
              skipChangesMade: true,
            })
          );
          toast("avatar updated");
          setTempAvatar(null);
        }
      })
      .catch((e) => {
        setIsUploadingAvatar(false);
      });
  };

  const pickRandomTemplate = () => {
    const avatarPositions = ["left", "right"];
    const buttonSizes = ["m", "s"];
    const buttonRadiuses = [0, 8, 16, 32];

    const textColor = colors[Math.floor(Math.random() * colors.length)];
    const mainBgColor = gradients[Math.floor(Math.random() * gradients.length)];
    const bgColor = gradients[Math.floor(Math.random() * gradients.length)];
    const font = fonts[Math.floor(Math.random() * fonts.length)];
    const buttonRadius =
      buttonRadiuses[Math.floor(Math.random() * buttonRadiuses.length)];

    const newSettings = {
      avatarUrl: settings.avatarUrl,
      template: "template1",
      mainColor: textColor,
      mainBgColor: mainBgColor,
      bgColor: bgColor,
      textColor: textColor,
      buttonTextColor: textColor,
      buttonRadius: buttonRadius,
      buttonSize: buttonSizes[Math.floor(Math.random() * buttonSizes.length)],
      font: font.value,
      avatarPosition:
        avatarPositions[Math.floor(Math.random() * avatarPositions.length)],
    };

    dispatch(setAllSettings(newSettings));
    setShowAutoGenerateStyling(false);
  };

  return (
    <>
      <FlexRow gap={32} block>
        <Col flex={1}>
          <h2>page background</h2>
          <Separator size={16} />
          <ColorPicker
            color={settings.mainBgColor}
            onSelect={(c) =>
              dispatch(setSetting({ parameter: "mainBgColor", value: c }))
            }
            withGradient
            withImages
          />
        </Col>
        <Col flex={1}>
          <h2>card background</h2>
          <Separator size={16} />
          <ColorPicker
            color={settings.bgColor}
            onSelect={(c) =>
              dispatch(setSetting({ parameter: "bgColor", value: c }))
            }
            withGradient
          />
        </Col>
      </FlexRow>

      <Separator size={48} />

      <FlexRow gap={32} block>
        <Col flex={1}>
          <h2>title</h2>
          <Separator size={16} />
          <ColorPicker
            color={settings.mainColor}
            onSelect={(c) =>
              dispatch(setSetting({ parameter: "mainColor", value: c }))
            }
          />
        </Col>
        <Col flex={1}>
          <h2>text</h2>
          <Separator size={16} />
          <ColorPicker
            color={settings.textColor}
            onSelect={(c) =>
              dispatch(setSetting({ parameter: "textColor", value: c }))
            }
          />
        </Col>
      </FlexRow>

      <Separator size={48} />

      <FlexRow gap={32} block>
        <Col flex={1}>
          <h2>button color</h2>
          <Separator size={16} />
          <ColorPicker
            color={settings.buttonTextColor}
            onSelect={(c) =>
              dispatch(setSetting({ parameter: "buttonTextColor", value: c }))
            }
          />
        </Col>
        <Col flex={1}>
          <h2>button border</h2>
          <Separator size={16} />
          <Row gap={16}>
            {buttonRadiuses.map((r) => (
              <Button
                block
                outline={settings.buttonRadius !== r}
                onClick={() =>
                  dispatch(setSetting({ parameter: "buttonRadius", value: r }))
                }
                key={"button-radius-" + r}
              >
                <Centered>
                  <RadiusSample
                    radius={r}
                    selected={settings.buttonRadius === r}
                  />
                </Centered>
              </Button>
            ))}
          </Row>
        </Col>
      </FlexRow>

      <Separator size={48} />

      <h2>others</h2>
      <Separator size={32} />
      <FlexRow gap={16} block>
        <Col flex={1}>
          <Select
            label={"font"}
            name={"font"}
            options={fonts}
            value={settings.font || "sarabun"}
            onChange={(v) => {
              dispatch(
                setSetting({ parameter: "font", value: v.target.value })
              );
            }}
          />
        </Col>
        <Col flex={1}>
          <Select
            label={"button size"}
            value={settings.buttonSize}
            options={[
              {
                label: "small",
                value: "s",
              },
              {
                label: "regular",
                value: "m",
              },
            ]}
            onChange={(e) =>
              dispatch(
                setSetting({ parameter: "buttonSize", value: e.target.value })
              )
            }
          />
        </Col>
        <Col flex={1}>
          <Select
            label={"avatar position"}
            value={settings.avatarPosition}
            options={[
              {
                label: "left",
                value: "left",
              },
              {
                label: "right",
                value: "right",
              },
              {
                label: "hidden",
                value: "hidden",
              },
            ]}
            onChange={(e) =>
              dispatch(
                setSetting({
                  parameter: "avatarPosition",
                  value: e.target.value,
                })
              )
            }
          />
        </Col>
      </FlexRow>

      {enableAvatarPictures && (
        <>
          <Separator size={48} />

          <FlexRow gap={16} block>
            <Col flex={1}>
              <FlexRow gap={16}>
                <FileUploader
                  disabled={isUploadingAvatar}
                  handleChange={(f) => setTempAvatar(f)}
                  name="file"
                  types={["JPG", "PNG", "JPEG"]}
                  hoverTitle="drop here"
                  label="click to upload, or drop a file"
                  minSize={0}
                  maxSize={1}
                  onSizeError={() => {
                    setAvatarError("file size exceeded, maximum size is 1mb");
                  }}
                  onTypeError={() => {
                    setAvatarError("supported file types: jpg, jpeg, png");
                  }}
                  children={
                    <UploadContainer>
                      <UploadContainerLabel>avatar</UploadContainerLabel>
                      {!tempAvatar && (
                        <span>click to upload, or drop a file</span>
                      )}
                      {tempAvatar && <span>{tempAvatar.name}</span>}
                    </UploadContainer>
                  }
                />
                <Button
                  onClick={submitAvatar}
                  disabled={!tempAvatar}
                  loading={isUploadingAvatar}
                >
                  upload avatar
                </Button>
                {settings.avatarUrl && (
                  <Button
                    onClick={() =>
                      dispatch(
                        setSetting({ parameter: "avatarUrl", value: null })
                      )
                    }
                    status="danger"
                  >
                    remove current avatar
                  </Button>
                )}
              </FlexRow>
            </Col>
          </FlexRow>
          {avatarError && (
            <>
              <Separator />
              <InfoBar>{avatarError}</InfoBar>
            </>
          )}
        </>
      )}

      <Separator size={32} />
      <RoundGrayContainer>
        <Centered>
          <Button onClick={() => setShowAutoGenerateStyling(true)} outline>
            surprise me, randomize my design
          </Button>
        </Centered>
      </RoundGrayContainer>

      <Modal
        title={"autogenerate styling"}
        visible={showAutoGenerateStyling}
        actionLabel={"yes, surprise me"}
        showOverlay
        showCancelButton
        showActionButton
        onAct={() => pickRandomTemplate()}
        onClose={() => setShowAutoGenerateStyling(false)}
      >
        are you sure you want to autogenerate some styles? this will overwrite
        your current settings.
      </Modal>
    </>
  );
};

export default Customization;
