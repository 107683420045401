import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  Centered,
  Container,
  Info,
  InfoGray,
  Separator,
} from "../../components/layout/Other";
import {
  addHeadlineLink,
  addLink,
  removeHeadlineLink,
  removeLink,
  setHeadlineLinks,
  setLinks,
} from "../../redux/features/profile/profileSlice";
import Modal from "../../components/layout/Modal";
import Input from "../../components/layout/Input";
import Select from "../../components/layout/Select";
import {
  contentLinkList,
  linkList,
  mostPopularLinkList,
  videoLinkType,
} from "./linkList";
import { IconButton, IconWrapper } from "./layout";
import OrderableLinks from "./OrderableLinks";
import { FaLink } from "react-icons/fa";
import { enablePages } from "../../lib/flags";
import { CgWebsite } from "react-icons/cg";

const IconsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;

  @media only screen and (max-width: 900px) {
    justify-content: center;
  }
`;

const Links = () => {
  const [showAll, setShowAll] = useState(false);
  const [addLinkType, setAddLinkType] = useState("");
  const [showAddLink, setShowAddLink] = useState(false);
  const [addLinkUrl, setAddLinkUrl] = useState("");
  const [addLinkVariant, setAddLinkVariant] = useState("headline");
  const [addLinkName, setAddLinkName] = useState("");
  const [addLinkError, setAddLinkError] = useState("");
  const [addLinkNameError, setAddLinkNameError] = useState("");
  const regularLinks = useSelector((state) => state.profile.links);
  const headlineLinks = useSelector((state) => state.profile.headlineLinks);
  const pages = useSelector((state) => state.profile.pages);
  const dispatch = useDispatch();

  useEffect(() => {
    if (addLinkUrl.substr(0, 8).toLowerCase() === "https://") {
      setAddLinkUrl(addLinkUrl.substr(8));
    }
  }, [addLinkUrl]);

  return (
    <>
      <h2>add link</h2>
      <InfoGray>{showAll ? "all options" : "most popular"}</InfoGray>
      <Separator size={16} />
      <IconsRow>
        {(showAll
          ? Object.values(linkList)
          : Object.values(mostPopularLinkList)
        ).map((l) => (
          <IconButton
            key={l.name}
            onClick={() => {
              setShowAddLink(true);
              setAddLinkType(l.type);
              setAddLinkUrl("");
              setAddLinkError("");
              setAddLinkName("");
              setAddLinkVariant(
                l.type === "custom" ||
                  l.type === "separator" ||
                  l.category === "video"
                  ? "regular"
                  : "headline"
              );
            }}
          >
            <IconWrapper>{l.icon}</IconWrapper>
            <Centered>{l.name}</Centered>
          </IconButton>
        ))}
        {!showAll && (
          <IconButton onClick={() => setShowAll(true)}>
            <div>see all</div>
          </IconButton>
        )}
      </IconsRow>

      <Separator size={32} />

      <h2>add embedded content</h2>
      <Separator size={16} />
      <IconsRow>
        {Object.values(contentLinkList).map((l) => (
          <IconButton
            key={l.name}
            onClick={() => {
              setShowAddLink(true);
              setAddLinkType(l.type);
              setAddLinkUrl("");
              setAddLinkError("");
              setAddLinkName("");
              setAddLinkVariant("regular");
            }}
          >
            <IconWrapper>{l.icon}</IconWrapper>
            <Centered>{l.name}</Centered>
          </IconButton>
        ))}
      </IconsRow>

      {enablePages && (
        <>
          <Separator size={32} />
          <h2>add link to custom page</h2>
          <Separator size={16} />
          <IconsRow>
            {pages.map((p) => (
              <IconButton
                key={p.id}
                onClick={() => {
                  dispatch(
                    addLink({
                      type: "user-defined-page",
                      url: "user-defined-page:" + p.id,
                      name: p.title,
                    })
                  );
                }}
              >
                <IconWrapper>
                  <CgWebsite />
                </IconWrapper>
                <Centered>{p.title}</Centered>
              </IconButton>
            ))}
          </IconsRow>
        </>
      )}

      <Separator size={32} />
      <h2>headline links</h2>
      {headlineLinks.length > 0 && (
        <>
          <InfoGray>drag & drop to reorder or delete</InfoGray>
          <OrderableLinks
            droppableId="headlineLinksDroppableId"
            links={headlineLinks}
            setLinks={(items) => dispatch(setHeadlineLinks(items))}
            removeLink={(i) => dispatch(removeHeadlineLink(i))}
          />
          <Separator size={8} />
        </>
      )}
      {headlineLinks.length === 0 && (
        <>
          <Info>no links added</Info> <Separator size={16} />
        </>
      )}

      <h2>regular links</h2>
      {regularLinks.length > 0 && (
        <>
          <InfoGray>drag & drop to reorder or delete</InfoGray>
          <OrderableLinks
            droppableId="regularLinksDroppableId"
            links={regularLinks}
            setLinks={(items) => dispatch(setLinks(items))}
            removeLink={(i) => dispatch(removeLink(i))}
          />

          <Separator size={8} />
        </>
      )}
      {regularLinks.length === 0 && <Info>no links added</Info>}

      <Modal
        title={`add ${
          addLinkType !== "user-defined-page"
            ? addLinkType
            : ` button to "${addLinkName}"`
        }`}
        showOverlay={true}
        visible={showAddLink}
        actionLabel="add link"
        showCancelButton={true}
        showActionButton={true}
        onClose={() => setShowAddLink(false)}
        onAct={() => {
          if (addLinkType !== "separator" && !addLinkUrl) {
            setAddLinkError("the entered link is invalid");
            return;
          }

          if (
            (addLinkType === "custom" || addLinkType === "separator") &&
            !addLinkName
          ) {
            setAddLinkError("the entered link name is invalid");
            return;
          }

          dispatch(
            addLinkVariant === "headline"
              ? addHeadlineLink({
                  type: addLinkType,
                  url: addLinkUrl,
                  name: addLinkName,
                })
              : addLink({
                  type: addLinkType,
                  url: addLinkUrl,
                  name: addLinkName,
                })
          );
          setShowAddLink(false);
        }}
      >
        <Container>
          {addLinkType !== "separator" && addLinkType !== "user-defined-page" && (
            <>
              <Input
                label={`your ${addLinkType} url`}
                type={"text"}
                prefix={"https://"}
                onChange={(e) => setAddLinkUrl(e.target.value)}
                value={addLinkUrl}
                inlineError={addLinkError}
              />
              <Separator size={32} />
            </>
          )}

          {addLinkType === "custom" && (
            <>
              <Separator size={32} />
              <Input
                label={`custom link name`}
                type={"text"}
                onChange={(e) => setAddLinkName(e.target.value)}
                value={addLinkName}
                inlineError={addLinkNameError}
              />
              <Separator size={32} />
            </>
          )}

          {addLinkType === "separator" && (
            <>
              <Input
                label={`separator name`}
                type={"text"}
                onChange={(e) => setAddLinkName(e.target.value)}
                value={addLinkName}
                inlineError={addLinkNameError}
              />
              <Separator size={32} />
            </>
          )}

          {addLinkType !== "custom" &&
            addLinkType !== "separator" &&
            videoLinkType.indexOf(addLinkType) === -1 && (
              <>
                <Select
                  label={`button type`}
                  onChange={(e) => setAddLinkVariant(e.target.value)}
                  value={addLinkVariant}
                  options={[
                    {
                      label: "round, under the headline",
                      value: "headline",
                    },
                    {
                      label: "regular button",
                      value: "regular",
                    },
                  ]}
                />
              </>
            )}
        </Container>
      </Modal>
    </>
  );
};

export default Links;
