import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
} from "recharts";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Tabs, { StickyTabsWrapper } from "../../components/layout/Tabs";
import {
  Centered,
  InfoBar,
  InfoBarPrimary,
  InfoGray,
  RoundContainer,
  Separator,
} from "../../components/layout/Other";
import Row from "../../components/layout/Row";
import Col from "../../components/layout/Col";

import { chartColors, colors as allColors } from "../../lib/constants";
import { colors as appColors } from "../../components/layout/constants";
import { bGetAnalytics } from "../../backend/event/event";
import moment from "moment";
import { videoLinkType } from "../links/linkList";

const ChartTitle = styled.h3`
  padding-top: 16px;
  text-align: center;
`;

const ChartRow = styled(Row)`
  min-height: 400px;
  //max-height: 600px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const ChartCol = styled(Col)`
  border-radius: 8px;
  background-color: ${appColors.lightGray};
  display: flex;
  flex-direction: column;

  max-height: 400px;

  @media only screen and (max-width: 900px) {
    min-height: 450px;
  }
`;

const StyledTooltip = styled.div`
  padding: 4px 8px;
  background-color: #ffffff;
  font-weight: bold;
  border: solid 2px ${({ color }) => color};
  border-radius: 16px;
`;

const StyledTooltipText = styled.div`
  color: ${({ color }) => color};
  font-size: 13px;
`;

const TooltipContent = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip
        color={payload.length > 1 ? appColors.primary : payload[0].payload.fill}
      >
        {payload.map((p) => (
          <StyledTooltipText color={p.payload.fill || p.stroke} key={p.name}>
            {p.name}: {p.value}
          </StyledTooltipText>
        ))}
      </StyledTooltip>
    );
  }

  return null;
};

/*
const processViewsData = (views) => {
  const deviceTypeCount = {};
  const osCount = {};
  const clientNameCount = {};
  const viewsPerDay = {};

  for (let i = 0; i < views.length; i++) {
    if (deviceTypeCount[views[i].data.deviceType]) {
      deviceTypeCount[views[i].data.deviceType]++;
    } else {
      deviceTypeCount[views[i].data.deviceType] = 1;
    }

    if (osCount[views[i].data.osName]) {
      osCount[views[i].data.osName]++;
    } else {
      osCount[views[i].data.osName] = 1;
    }

    if (clientNameCount[views[i].data.clientName]) {
      clientNameCount[views[i].data.clientName]++;
    } else {
      clientNameCount[views[i].data.clientName] = 1;
    }

    const date = moment(views[i].createdAt).format("MMMM Do YYYY");
    if (viewsPerDay[date]) {
      viewsPerDay[date]++;
    } else {
      viewsPerDay[date] = 1;
    }
  }

  //   { name: "Group A", value: 2400 },
  const formattedDeviceTypeCount = [];
  Object.keys(deviceTypeCount).map((deviceType) => {
    formattedDeviceTypeCount.push({
      name: deviceType,
      value: deviceTypeCount[deviceType],
    });
  });

  const formattedOSCount = [];
  Object.keys(osCount).map((os) => {
    formattedOSCount.push({
      name: os,
      value: osCount[os],
    });
  });

  const formattedViewsPerDay = [];
  Object.keys(viewsPerDay).map((date) => {
    formattedViewsPerDay.push({
      name: date,
      views: viewsPerDay[date],
    });
  });

  const formattedClientName = [];
  Object.keys(clientNameCount).map((clientName) => {
    formattedClientName.push({
      name: clientName,
      value: clientNameCount[clientName],
    });
  });

  return {
    deviceType: formattedDeviceTypeCount,
    os: formattedOSCount,
    views: formattedViewsPerDay,
    clientName: formattedClientName,
  };
};
*/

const Analytics = ({ loading }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [views, setViews] = useState({
    views: [],
    os: [],
    deviceType: [],
    clientName: [],
  });
  const [clicks, setClicks] = useState([]);
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    if (loading) {
      return;
    }

    setIsLoading(true);
    bGetAnalytics().then((data) => {
      setIsLoading(false);
      setViews(data.views);
      setClicks(data.clicks);
    });
  }, [loading]);

  if (loading) {
    return (
      <Row gap={32}>
        <Col flex={1}>
          <Skeleton height={400} />
        </Col>
        <Col flex={1}>
          <Skeleton height={400} />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <StickyTabsWrapper>
        <Tabs
          selected={selectedTab}
          onSelect={(t) => setSelectedTab(t)}
          // tabs={[{ label: "visits" }, { label: "clicks" }, { label: "export" }]}
          tabs={[{ label: "visits" }, { label: "clicks" }]}
        />
      </StickyTabsWrapper>
      <Separator />
      <InfoBarPrimary>
        the analytics page is a work in progress, but we made it public to get
        early feedback. if you have any suggestion, we highly encourage you to{" "}
        <a href="https://form.typeform.com/to/CtHXgBZV" target="_blank">
          contact us
        </a>
      </InfoBarPrimary>
      <Separator />
      {selectedTab === 0 && (
        <RoundContainer>
          <ChartRow gap={32}>
            <ChartCol flex={1}>
              <ChartTitle>page visits</ChartTitle>
              <ResponsiveContainer
                width="100%"
                height="91%"
                style={{ flex: 1 }}
              >
                <LineChart
                  width={500}
                  height={300}
                  data={views.views}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 20" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={<TooltipContent />} />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="views"
                    stroke="#8884d8"
                    strokeWidth={3}
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartCol>
            <ChartCol flex={1}>
              <ChartTitle>os share</ChartTitle>
              <ResponsiveContainer width="100%" height="91%">
                <PieChart width={500} height={300}>
                  <Pie
                    dataKey="value"
                    data={views.os}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={120}
                    outerRadius={160}
                    fill="#82ca9d"
                    paddingAngle={5}
                  >
                    {views.os.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={chartColors[index % chartColors.length]}
                      />
                    ))}
                  </Pie>
                  <Legend />
                  <Tooltip content={<TooltipContent />} />
                </PieChart>
              </ResponsiveContainer>
            </ChartCol>
          </ChartRow>
          <Separator size={32} />
          <ChartRow gap={32}>
            <ChartCol flex={1}>
              <ChartTitle>device share</ChartTitle>
              <ResponsiveContainer width="100%" height="91%">
                <PieChart width={500} height={300}>
                  <Pie
                    dataKey="value"
                    data={views.deviceType}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={120}
                    outerRadius={160}
                    fill="#82ca9d"
                    paddingAngle={5}
                  >
                    {views.deviceType.map((entry, index) => (
                      <Cell
                        key={`cell-device-${index}`}
                        fill={chartColors[(index + 11) % chartColors.length]}
                      />
                    ))}
                  </Pie>
                  <Legend />
                  <Tooltip content={<TooltipContent />} />
                </PieChart>
              </ResponsiveContainer>
            </ChartCol>
            <ChartCol flex={1}>
              <ChartTitle>client share</ChartTitle>
              <ResponsiveContainer width="100%" height="91%">
                <PieChart width={500} height={300}>
                  <Pie
                    dataKey="value"
                    data={views.clientName}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={120}
                    outerRadius={160}
                    fill="#82ca9d"
                    paddingAngle={5}
                  >
                    {views.clientName.map((entry, index) => (
                      <Cell
                        key={`cell-client-${index}`}
                        fill={chartColors[(index + 4) % chartColors.length]}
                      />
                    ))}
                  </Pie>
                  <Legend />
                  <Tooltip content={<TooltipContent />} />
                </PieChart>
              </ResponsiveContainer>
            </ChartCol>
          </ChartRow>
          <Centered>
            <Separator size={16} />
            <InfoGray>last 7 days</InfoGray>
          </Centered>
        </RoundContainer>
      )}

      {selectedTab === 1 && (
        <RoundContainer>
          <ChartRow gap={32}>
            <ChartCol flex={1}>
              <ChartTitle>link clicks</ChartTitle>
              <ResponsiveContainer width="100%" height="91%">
                <LineChart
                  width={500}
                  height={300}
                  data={clicks}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 20" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip content={<TooltipContent />} />
                  <Legend />
                  {[...profile.links, ...profile.headlineLinks]
                    .filter(
                      (l) =>
                        l.type !== "separator" &&
                        videoLinkType.indexOf(l.type) === -1
                    )
                    .map((l, k) => (
                      <Line
                        key={`click-line-${l.id}`}
                        type="monotone"
                        dataKey={l.name || l.type || l.url}
                        stroke={allColors[(3 * k + 14) % allColors.length]}
                        strokeWidth={3}
                        activeDot={{ r: 8 }}
                      />
                    ))}
                </LineChart>
              </ResponsiveContainer>
            </ChartCol>
          </ChartRow>
          <Separator size={32} />
          <Centered>
            <Separator size={16} />
            <InfoGray>last 7 days</InfoGray>
          </Centered>
        </RoundContainer>
      )}
    </>
  );
};

export default Analytics;
