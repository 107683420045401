import React from "react";
import styled from "styled-components";

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap}px;
  ${({ block }) => (block ? "width: 100%;" : "")}
`;

const Row = ({ children, gap, block, ...props }) => {
  return (
    <StyledRow gap={gap} block={block} {...props}>
      {children}
    </StyledRow>
  );
};

export default Row;

export const FlexRow = styled(Row)`
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
