import styled, { keyframes } from "styled-components";
import {colors} from "./constants";

const animation = keyframes`
  0% {
    left: 0;
    right: 100%;
    width: 0;
  }
  10% {
    left: 0;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right:0;
    width:0;
  }
`;

const Loader = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background-color: ${colors.primaryHover};
`;

const LoaderBar = styled.div`
  position: absolute;
  border-radius: 10px;
  right: 100%;
  left: 0;
  background: ${colors.primary};
  width: 0;
  height: 8px;
  animation: ${animation} 2s linear infinite;
`;

const LoadingBar = () => {
  return (
    <Loader>
      <LoaderBar />
    </Loader>
  );
};

export default LoadingBar;
