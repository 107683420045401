import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Row from "../../components/layout/Row";
import { linkList } from "./linkList";
import { FaLink, FaTrash } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import styled from "styled-components";
import { IconButton, IconWrapper, IconTitle } from "./layout";
import { colors } from "../../components/layout/constants";
import { Separator } from "../../components/layout/Other";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  // margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  borderRadius: 8,
  background: isDraggingOver ? colors.infoHover : "#f5f6fa",
  display: "flex",
  padding: `${grid}px`,
  alignItems: "center",
  overflow: "auto",
  gap: 8,
});

const getDeleteListStyle = (isDraggingOver, isDragging) => ({
  borderRadius: 8,
  background: isDraggingOver ? "#ff6b81" : "#ff6b8133",
  display: "flex",
  marginLeft: 0,
  padding: 0,
  justifyContent: "center",
  fontSize: 13,
  alignItems: "center",
  overflow: "auto",
  width: "100%",
  height: 50,
  visibility: isDragging ? "visible" : "hidden",
  marginTop: isDragging || isDraggingOver ? 16 : 0,
  // marginBottom: isDragging ? 0 : -80
});

const DropToDelete = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ListContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

const OrderableLinks = ({ droppableId, links = [], setLinks, removeLink }) => {
  const [isDragging, setIsDragging] = useState(false);

  const onDragStart = () => {
    setIsDragging(true);
  };
  const onDragEnd = (result) => {
    setIsDragging(false);
    if (!result.destination) {
      return;
    }

    if (result.destination.droppableId === "delete") {
      removeLink(result.source.index);
      return;
    }

    const items = reorder(links, result.source.index, result.destination.index);

    setLinks(items);
  };

  const getItemTitle = (item) => {
    if (item.type === "separator") {
      return item.name;
    }

    if (item.type === "user-defined-page") {
      return item.name;
    }

    if (linkList[item.type]?.name !== "custom") {
      return item.type;
    }

    return item.name.substr(0, 9) + "..." || item.type;
  };

  const getItemIcon = (item) => {
    if (item.type === "user-defined-page") {
      return <CgWebsite />;
    }

    return linkList[item.type]?.icon;
  };

  return (
    <ListContainer>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId={droppableId} direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {links.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <IconButton
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <IconWrapper>{getItemIcon(item)}</IconWrapper>
                      <IconTitle>{getItemTitle(item)}</IconTitle>
                    </IconButton>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="delete">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getDeleteListStyle(snapshot.isDraggingOver, isDragging)}
              {...provided.droppableProps}
            >
              {!snapshot.isDraggingOver && (
                <DropToDelete>
                  <IconWrapper>
                    <FaTrash />
                  </IconWrapper>
                  drop here to delete
                </DropToDelete>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ListContainer>
  );
};

export default OrderableLinks;
