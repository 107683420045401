import axios from "axios";
import { API_URL } from "../api";
import { getToken } from "../session";

export const bCheckUrlAvailability = async (url) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/user/check-url`,
        { url },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          },
        }
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const bSaveUrl = async (url) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/user/save-url`,
        { url },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          },
        }
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
