import styled from "styled-components";

export const IconButton = styled.div`
  border: solid 1px #bdc3c7;
  background-color: #f5f6fa;
  border-radius: 4px;
  padding: 8px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  transition: background-color 0.15s ease-in;

  &:hover {
    background-color: #bdc3c7;
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div`
  font-size: 18px;
`;

export const IconTitle = styled.div`
  text-align: center;
  max-height: 48px;
`;
