import styled from "styled-components";
import { Container, Separator } from "../../components/layout/Other";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  addLink,
  removeLink,
  setSetting,
  setLinks,
  setName,
  setLocation,
  setHeadline,
  setBio,
} from "../../redux/features/profile/profileSlice";
import Row from "../../components/layout/Row";
import Modal from "../../components/layout/Modal";
import Input from "../../components/layout/Input";
import ColorPicker from "../../components/ColorPicker";
import Select from "../../components/layout/Select";
import Col from "../../components/layout/Col";
import Textarea from "../../components/layout/Textarea";

const IconButton = styled.div`
  border: solid 1px #bdc3c7;
  background-color: #f5f6fa;
  border-radius: 4px;
  padding: 8px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;

  &:hover {
    background-color: #bdc3c7;
    cursor: pointer;
  }
`;

const Information = () => {
  const info = useSelector((state) => state.profile.info);
  const dispatch = useDispatch();

  return (
    <>
      <h2>profile information</h2>
      <Separator size={32} />
      <Row gap={16} block>
        <Col flex={1}>
          <Input
            label={"name"}
            value={info.name}
            onChange={(e) => dispatch(setName(e.target.value))}
            required
          />
        </Col>
        <Col flex={1}>
          <Input
            label={"location"}
            value={info.location}
            onChange={(e) => dispatch(setLocation(e.target.value))}
          />
        </Col>
      </Row>
      <Separator size={32} />
      <Row gap={16} block>
        <Col flex={1}>
          <Input
            label={"headline"}
            value={info.headline}
            onChange={(e) => dispatch(setHeadline(e.target.value))}
          />
        </Col>
      </Row>
      <Separator size={32} />
      <Row gap={16} block>
        <Col flex={1}>
          <Textarea
            label={"bio"}
            value={info.bio}
            onChange={(e) => dispatch(setBio(e.target.value))}
          />
        </Col>
      </Row>
    </>
  );
};

export default Information;
