import styled from "styled-components";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { Centered } from "./layout/Other";
import { videoLinkType } from "../screens/links/linkList";

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
`;

const getPaddingBySize = (size) => {
  switch (size) {
    case "s":
      return "8px 4px";
    case "m":
    default:
      return "16px 8px";
  }
};

const getBorderRadiusBySize = (size, radius) => {
  switch (size) {
    case "s":
      return `${radius / 2}px`;
    case "m":
    default:
      return `${radius}px`;
  }
};

const Link = styled.a`
  padding: ${({ size }) => getPaddingBySize(size)};
  border-radius: ${({ size, radius }) => getBorderRadiusBySize(size, radius)};
  border: solid 1px #bdc3c777;
  background-color: #ecf0f177;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s ease-in;
  text-align: center;

  &:hover {
    background-color: #bdc3c777;
  }
`;

const LinkSeparator = styled.div`
  border: ${({ color }) => color}77;
  color: ${({ color }) => color}77;
  font-size: 13px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;

  :before,
  :after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 46%;
    height: 1px;
    content: "\\a0";
    background-color: ${({ color }) => color}77;
  }

  :after {
    margin-left: 4%;
  }
  :before {
    margin-left: -50%;
    text-align: right;
  }
`;

const VideoWrapper = styled(Centered)`
  border-radius: ${({ size, radius }) => getBorderRadiusBySize(size, radius)};
  border: solid 1px #bdc3c777;
  background-color: #ecf0f177;
  overflow: hidden;
`;

const getLinkLabel = ({ type, url }) => {
  switch (type) {
    case "facebook":
      return "Facebook";
    case "instagram":
      return "Instagram";
    case "linkedin":
      return "LinkedIn";
  }

  return url;
};

const Links = ({ links = [], onSelectPage = () => {} }) => {
  const settings = useSelector((state) => state.profile.settings);

  return (
    <LinksWrapper>
      {links.map((l) => {
        if (l.type === "separator") {
          return (
            <LinkSeparator
              size={settings.buttonSize}
              color={settings.textColor}
              key={`link-separator-${l.id}`}
            >
              {l.name}
            </LinkSeparator>
          );
        }

        if (videoLinkType.indexOf(l.type) > -1) {
          return (
            <VideoWrapper
              color={settings.buttonTextColor}
              size={settings.buttonSize}
              radius={settings.buttonRadius}
              key={`link-video-${l.id}`}
            >
              <ReactPlayer
                url={l.url}
                style={{ maxWidth: "100%" }}
              />
            </VideoWrapper>
          );
        }

        if (l.type === "user-defined-page") {
          return (
            <Link
              key={l.id}
              size={settings.buttonSize}
              radius={settings.buttonRadius}
              color={settings.buttonTextColor}
              onClick={() =>
                onSelectPage(l.url.substr("user-defined-page".length + 1))
              }
            >
              {l.name || getLinkLabel(l)}
            </Link>
          );
        }

        return (
          <Link
            key={l.id}
            size={settings.buttonSize}
            radius={settings.buttonRadius}
            color={settings.buttonTextColor}
          >
            {l.name || getLinkLabel(l)}
          </Link>
        );
      })}
    </LinksWrapper>
  );
};

export default Links;
