import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  info: {
    name: "",
    pictureUrl: "",
    headline: "",
    bio: "",
    location: "",
    prettyUrl: "",
  },
  headlineLinks: [],
  links: [],
  settings: {
    template: "template1",
    mainColor: "#ffffff",
    mainBgColor: "#ffffff",
    bgColor: "#ffffff",
    textColor: "#ffffff",
    buttonTextColor: "#ffffff",
    buttonSize: "m",
    avatarPosition: "left",
    buttonRadius: 8,
  },
  pages: [],
  changesMade: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    addLink: (state, action) => {
      const newLinks = [...state.links];
      newLinks.push({ ...action.payload, id: uuidv4() });
      state.links = newLinks;
      state.changesMade = true;
    },
    removeLink: (state, action) => {
      const newLinks = [...state.links];
      newLinks.splice(action.payload, 1);
      state.links = newLinks;
      state.changesMade = true;
    },
    setLinks: (state, action) => {
      state.links = action.payload;
      state.changesMade = true;
    },
    addHeadlineLink: (state, action) => {
      const newLinks = [...state.headlineLinks];
      newLinks.push({ ...action.payload, id: uuidv4() });
      state.headlineLinks = newLinks;
      state.changesMade = true;
    },
    removeHeadlineLink: (state, action) => {
      const newLinks = [...state.headlineLinks];
      newLinks.splice(action.payload, 1);
      state.headlineLinks = newLinks;
      state.changesMade = true;
    },
    setHeadlineLinks: (state, action) => {
      state.headlineLinks = action.payload;
      state.changesMade = true;
    },
    setName: (state, action) => {
      state.info.name = action.payload;
      state.changesMade = true;
    },
    setHeadline: (state, action) => {
      state.info.headline = action.payload;
      state.changesMade = true;
    },
    setBio: (state, action) => {
      state.info.bio = action.payload;
      state.changesMade = true;
    },
    setLocation: (state, action) => {
      state.info.location = action.payload;
      state.changesMade = true;
    },
    setSetting: (state, action) => {
      state.settings[action.payload.parameter] = action.payload.value;
      if (!action.payload.skipChangesMade) {
        state.changesMade = true;
      }
    },
    setAllSettings: (state, action) => {
      state.settings = action.payload;
      state.changesMade = true;
    },
    resetChangesMade: (state) => {
      state.changesMade = false;
    },
    setPrettyUrl: (state, action) => {
      state.info.prettyUrl = action.payload;
    },
    setProfile: (state, action) => {
      state.info = action.payload.info;
      state.links = action.payload.links;
      state.headlineLinks = action.payload.headlineLinks;
      state.settings = action.payload.settings;
      state.pages = action.payload.pages;
    },
    addPage: (state) => {
      if (!state.pages) {
        state.pages = [
          {
            id: uuidv4(),
            title: "",
            content: "",
          },
        ];
      } else {
        state.pages.push({
          id: uuidv4(),
          title: "",
          content: "",
        });
      }
    },
    editPage: (state, action) => {
      const newPages = [...state.pages];
      newPages[action.id] = action.page;
      state.pages = newPages;
      state.changesMade = true;
    },
    removePage: (state, action) => {
      const newPages = [...state.pages];
      newPages.splice(action.payload, 1);
      state.pages = newPages;
      state.changesMade = true;
    },
  },
});

export const {
  addLink,
  removeLink,
  setLinks,
  addHeadlineLink,
  removeHeadlineLink,
  setHeadlineLinks,
  setName,
  setHeadline,
  setBio,
  setLocation,
  setSetting,
  setAllSettings,
  resetChangesMade,
  setPrettyUrl,
  setProfile,
  addPage,
  editPage,
  removePage,
} = profileSlice.actions;

export default profileSlice.reducer;
