import { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createReactEditorJS } from "react-editor-js";
import { toast } from "react-toastify";
import styled from "styled-components";
import { FaPlusCircle } from "react-icons/fa";
import Row, { FlexRow } from "../../components/layout/Row";
import Col from "../../components/layout/Col";
import Input from "../../components/layout/Input";
import {
  Centered,
  InfoBar,
  RoundGrayContainer,
  Separator,
} from "../../components/layout/Other";
import Button from "../../components/layout/Button";
import {
  addPage,
  removePage,
  setProfile,
} from "../../redux/features/profile/profileSlice";
import { colors as appColors } from "../../components/layout/constants";
import { EDITOR_JS_TOOLS } from "./editor";
import { bSaveProfile } from "../../backend/profile/profile";
import Tooltip from "../../components/layout/Tooltip";
import Modal from "../../components/layout/Modal";

const EditorWrapper = styled.div`
  width: 100%;
  border-style: solid;
  border-width: 2px;
  border-radius: 16px;
  border-color: ${appColors.gray};
  padding: 0 16px;
  transition: border 0.15s ease-in;
  background-color: #ffffff;
`;

const PagesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PageItem = styled(RoundGrayContainer)`
  transition: filter 0.15s ease-in;

  ${({ clickable }) =>
    clickable
      ? `  :hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
`
      : ""}
`;

const Pages = () => {
  const [editedPage, setEditedPage] = useState({});
  const [editedPageIdx, setEditedPageIdx] = useState(-1);
  const [newPageIdx, setNewPageIdx] = useState(-1);
  const [pageIsEdited, setPageIsEdited] = useState(false);
  const profile = useSelector((state) => state.profile);
  const pages = useSelector((state) => state.profile.pages);
  const links = useSelector((state) => state.profile.links);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const ReactEditorJS = createReactEditorJS();

  const editorCore = useRef(null);
  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const savePage = useCallback(async () => {
    const savedData = await editorCore.current.save();
    const newPages = JSON.parse(JSON.stringify(pages));
    newPages[editedPageIdx].title = title;
    newPages[editedPageIdx].content = savedData.blocks;
    const { changesMade, ...newProfile } = { ...profile, pages: newPages };
    setIsLoading(true);
    bSaveProfile(newProfile).then(() => {
      dispatch(setProfile(newProfile));
      setIsLoading(false);
      toast("your page has been saved");
      setEditedPage({});
      setEditedPageIdx(-1);
    });
  }, [pages, editedPageIdx, title, profile, dispatch]);

  const deletePage = (idx) => {
    setIsLoading(true);
    const newPages = JSON.parse(JSON.stringify(pages));
    newPages.splice(idx);
    const { changesMade, ...newProfile } = { ...profile, pages: newPages };

    dispatch(removePage(idx));
    setIsLoading(true);
    bSaveProfile(newProfile).then(() => {
      setIsLoading(false);
    });
  };

  const pageHasLinks = useCallback(
    (pageId) => {
      console.log(`user-defined-page:${pageId}`);
      return (
        links.findIndex((l) => l.url === `user-defined-page:${pageId}`) > -1
      );
    },
    [links]
  );

  useEffect(() => {
    if (newPageIdx === -1) {
      return;
    }

    setEditedPageIdx(newPageIdx);
    setEditedPage(pages[newPageIdx]);
    setNewPageIdx(-1);
  }, [pages.length]);

  return (
    <>
      <h2>pages</h2>
      <Separator size={16} />
      <PagesColumn>
        {(!pages || !pages.length) && (
          <InfoBar>
            <Centered>no pages available</Centered>
          </InfoBar>
        )}

        {pages?.map((p, k) => {
          return (
            <PageItem
              key={p.id}
              clickable={k !== editedPageIdx}
              onClick={() => {
                if (k === editedPageIdx) {
                  return;
                }
                setEditedPage(p);
                setEditedPageIdx(k);
                setTitle(p.title);
              }}
            >
              {p.id !== editedPage.id && (
                <FlexRow>
                  <Col
                    flex={1}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h3>{p.title || "no title"}</h3>
                  </Col>
                  <Col flex={0}>
                    <Row gap={8}>
                      {/*
                      <Button
                        outline
                        onClick={() => {
                          setEditedPage(p);
                          setEditedPageIdx(k);
                          setTitle(p.title);
                        }}
                      >
                        edit
                      </Button>
*/}
                      <Tooltip
                        text={
                          pageHasLinks(p.id)
                            ? "cannot delete the page as you have links to it"
                            : null
                        }
                      >
                        <Button
                          status={"info"}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowDeleteModal(k);
                          }}
                          disabled={pageHasLinks(p.id)}
                        >
                          delete
                        </Button>
                      </Tooltip>
                    </Row>
                  </Col>
                </FlexRow>
              )}
              {p.id === editedPage.id && (
                <>
                  <Input
                    label="page title"
                    name="page title"
                    type="text"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setPageIsEdited(true);
                    }}
                    required
                  />
                  <Separator size={16} />
                  <EditorWrapper>
                    <ReactEditorJS
                      data={{ blocks: editedPage?.content || [] }}
                      onInitialize={handleInitialize}
                      enableReInitialize
                      tools={EDITOR_JS_TOOLS}
                    />
                  </EditorWrapper>
                  <Separator size={16} />
                  <Button
                    status="primary"
                    block
                    onClick={savePage}
                    loading={isLoading}
                    disabled={!title || !title.trim()}
                  >
                    save page
                  </Button>
                  <Separator size={8} />
                  <Button
                    status="info"
                    block
                    onClick={() => setShowCancelModal(true)}
                    disabled={isLoading}
                  >
                    discard
                  </Button>
                </>
              )}
            </PageItem>
          );
        })}
      </PagesColumn>
      <Separator size={16} />
      <Button
        block
        outline
        onClick={() => {
          setNewPageIdx(pages.length);
          dispatch(addPage());
          // setTimeout(() => {
          //   setEditedPage(pages[pages.length - 1]);
          //   setEditedPageIdx(pages.length - 1);
          //   setTitle("");
          // }, 1);
        }}
      >
        <FaPlusCircle /> create new page
      </Button>

      <Modal
        onAct={() => {
          setEditedPageIdx(-1);
          setEditedPage({});
          setTitle("");
          setShowCancelModal(false);
        }}
        onClose={() => setShowCancelModal(false)}
        title={"Discard changes"}
        actionLabel="yes, discard changes"
        showCloseButton
        showActionButton
        showOverlay
        visible={showCancelModal}
      >
        <div>
          Are you sure you want to discard your changes? All your page changes
          will be lost.
        </div>
      </Modal>

      <Modal
        onAct={() => {
          deletePage(showDeleteModal);
          setShowDeleteModal(false);
        }}
        onClose={() => setShowDeleteModal(false)}
        title={"Delete page"}
        actionLabel="yes, delete page"
        actionType="danger"
        showCloseButton
        showActionButton
        showOverlay
        visible={showDeleteModal !== false}
      >
        <div>Are you sure you want to delete the page?</div>
      </Modal>
    </>
  );
};

export default Pages;
