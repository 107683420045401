import React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "./constants";

const SpinnerWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
`;

const spinning = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  border: ${({ size }) => size}px solid #f5f6fa;
  border-top: ${({ size }) => size}px solid
    ${({ color }) => (color ? color : colors.primary)};
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: ${spinning} 2s linear infinite;
`;

const Spinner = ({ size = 24, color, ...props }) => {
  return (
    <SpinnerWrapper style={{ height: size, width: size }} {...props}>
      <StyledSpinner color={color} size={Math.floor(size / 12)} />
    </SpinnerWrapper>
  );
};

export default Spinner;
