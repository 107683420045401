import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Tabs, { StickyTabsWrapper } from "../../components/layout/Tabs";
import {
  Centered,
  RoundContainer,
  Separator,
} from "../../components/layout/Other";
import Row from "../../components/layout/Row";
import Col from "../../components/layout/Col";
import Information from "../information/Information";
import Links from "../links/Links";
import Customization from "../customization/Customization";
import Preview from "../preview/Preview";
import Button from "../../components/layout/Button";
import { resetChangesMade } from "../../redux/features/profile/profileSlice";
import { toast } from "react-toastify";
import Modal from "../../components/layout/Modal";
import Pages from "../pages/Pages";
import Share from "../share/Share";
import styled from "styled-components";
import { bSaveProfile } from "../../backend/profile/profile";
import { enablePages } from "../../lib/flags";
import { CSSTransition } from "react-transition-group";

import "./Personalize.scss";

const tabs = enablePages
  ? [
      { label: "information" },
      { label: "links" },
      { label: "pages" },
      { label: "theming" },
      { label: "share" },
    ]
  : [
      { label: "information" },
      { label: "links" },
      { label: "theming" },
      { label: "share" },
    ];

const ButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 32px 16px 16px 16px;
  margin-left: -16px;
  margin-right: -16px;
  background-image: linear-gradient(
    180deg,
    #ffffff00 0%,
    #ffffff 25%,
    #ffffff 100%
  );
  z-index: 111;
`;

const AnimatedCol = styled(Col)`
  max-width: 500px;
  ${({ collapsed }) =>
    collapsed
      ? `
  width: 0 !important; 
  flex: 0;
  margin-left: -32px;`
      : ""}
  transition: all 0.3s ease-in;
  overflow-x: hidden;
`;

const Personalize = ({ loading }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showPreview, setShowPreview] = useState(true);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const handleSave = () => {
    setIsLoading(true);
    const { changesMade, ...data } = profile;
    bSaveProfile(data)
      .then(() => {
        dispatch(resetChangesMade());
        toast("your changes have been saved");
        setIsLoading(false);
      })
      .catch(() => {
        toast(
          "an error has occurred while saving your changes, please try again"
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      <StickyTabsWrapper>
        <Tabs
          selected={selectedTab}
          onSelect={(t) => setSelectedTab(t)}
          tabs={tabs}
          noPadding
          scrollOverflow
        />
      </StickyTabsWrapper>
      <RoundContainer>
        <Row gap={32}>
          <Col flex={7} style={{ minWidth: 0 }}>
            {loading && (
              <>
                <Row gap={16}>
                  <Col flex={1}>
                    <Skeleton height={32} />
                  </Col>
                  <Col flex={1}>
                    <Skeleton height={32} />
                  </Col>
                </Row>
                <Separator size={32} />
                <Skeleton height={32} />
                <Separator size={32} />
                <Skeleton height={128} />
              </>
            )}

            {profile.changesMade && (
              <div className="show-mobile-block">
                <Button
                  status="info"
                  onClick={() => setShowPreviewModal(true)}
                  block
                >
                  show preview
                </Button>
                <Separator size={16} />
              </div>
            )}

            {!loading && enablePages && (
              <>
                {selectedTab === 0 && <Information />}
                {selectedTab === 1 && <Links />}
                {selectedTab === 2 && <Pages />}
                {selectedTab === 3 && <Customization />}
                {selectedTab === 4 && <Share />}
              </>
            )}
            {!loading && !enablePages && (
              <>
                {selectedTab === 0 && <Information />}
                {selectedTab === 1 && <Links />}
                {selectedTab === 2 && <Customization />}
                {selectedTab === 3 && <Share />}
              </>
            )}
          </Col>
          <CSSTransition
            in={showPreview}
            timeout={600}
            classNames="preview-animation"
          >
            <AnimatedCol
              flex={4}
              collapsed={!showPreview}
              className="hide-mobile"
            >
              <>
                {loading && (
                  <>
                    <Skeleton height={200} />
                    <Separator size={32} />
                    <Skeleton height={32} />
                  </>
                )}
                {!loading && <Preview />}
              </>
            </AnimatedCol>
          </CSSTransition>
        </Row>
        <Separator size={16} />
        <ButtonsWrapper>
          <Centered>
            <Row gap={8}>
              <Button status="info" onClick={() => setShowPreviewModal(true)}>
                expand preview
              </Button>

              {!showPreview && (!enablePages || selectedTab === 2) && (
                <Button status="primary" onClick={() => setShowPreview(true)}>
                  expand editor
                </Button>
              )}

              {showPreview && (!enablePages || selectedTab === 2) && (
                <Button status="primary" onClick={() => setShowPreview(false)}>
                  collapse editor
                </Button>
              )}

              {(!enablePages || selectedTab !== 2) && (
                <Button
                  status="danger"
                  outline={!profile.changesMade}
                  disabled={!profile.changesMade}
                  onClick={handleSave}
                  loading={isLoading}
                >
                  save changes
                </Button>
              )}
            </Row>
          </Centered>
        </ButtonsWrapper>
      </RoundContainer>
      <Centered
        className="show-mobile"
        style={{
          position: "sticky",
          bottom: 0,
          zIndex: 100,
          padding: "0 16px 16px 16px",
          backgroundColor: "#f5f6fa",
        }}
      >
        <Separator size={16} />
        {(!enablePages || selectedTab !== 2) && (
          <Button
            status="danger"
            outline={!profile.changesMade}
            disabled={!profile.changesMade}
            onClick={handleSave}
            loading={isLoading}
            block
          >
            save changes
          </Button>
        )}
      </Centered>

      <Modal
        showOverlay={true}
        onClose={() => setShowPreviewModal(false)}
        showCloseButton={true}
        visible={showPreviewModal}
        style={{ width: "90vw", top: "2vh" }}
      >
        <Preview style={{ height: "75vh" }} topPadding />
      </Modal>
    </>
  );
};

export default Personalize;
