import {
  FaAppStore,
  FaBandcamp,
  FaBehance,
  FaDeezer,
  FaDribbble,
  FaEtsy,
  FaFacebook,
  FaGithub,
  FaGooglePlay,
  FaGripLines,
  FaInstagram,
  FaKickstarter,
  FaLastfm,
  FaLink,
  FaLinkedin,
  FaMedium,
  FaMixcloud,
  FaPatreon,
  FaPaypal,
  FaProductHunt,
  FaSoundcloud,
  FaSpotify,
  FaStackOverflow,
  FaTwitch,
  FaTwitter,
  FaVimeo,
  FaVimeoSquare,
  FaWhatsapp,
  FaYoutube,
  FaYoutubeSquare,
} from "react-icons/fa";

const linkObj = {
  facebook: {
    type: "facebook",
    name: "facebook",
    icon: <FaFacebook />,
  },
  linkedin: {
    type: "linkedin",
    name: "linkedin",
    icon: <FaLinkedin />,
  },
  github: {
    type: "github",
    name: "github",
    icon: <FaGithub />,
  },
  behance: {
    type: "behance",
    name: "behance",
    icon: <FaBehance />,
  },
  twitter: {
    type: "twitter",
    name: "twitter",
    icon: <FaTwitter />,
  },
  instagram: {
    type: "instagram",
    name: "instagram",
    icon: <FaInstagram />,
  },
  youtube: {
    type: "youtube",
    name: "youtube",
    icon: <FaYoutube />,
  },
  dribbble: {
    type: "dribbble",
    name: "dribbble",
    icon: <FaDribbble />,
  },
  playStore: {
    type: "playStore",
    name: "play store",
    icon: <FaGooglePlay />,
  },
  appStore: {
    type: "appStore",
    name: "app store",
    icon: <FaAppStore />,
  },
  spotify: {
    type: "spotify",
    name: "spotify",
    icon: <FaSpotify />,
  },
  deezer: {
    type: "deezer",
    name: "deezer",
    icon: <FaDeezer />,
  },
  bandcamp: {
    type: "bandcamp",
    name: "bandcamp",
    icon: <FaBandcamp />,
  },
  etsy: {
    type: "etsy",
    name: "etsy",
    icon: <FaEtsy />,
  },
  kickstarter: {
    type: "kickstarter",
    name: "kickstarter",
    icon: <FaKickstarter />,
  },
  lastfm: {
    type: "lastfm",
    name: "lastfm",
    icon: <FaLastfm />,
  },
  medium: {
    type: "medium",
    name: "medium",
    icon: <FaMedium />,
  },
  paypal: {
    type: "paypal",
    name: "paypal",
    icon: <FaPaypal />,
  },
  producthunt: {
    type: "producthunt",
    name: "product hunt",
    icon: <FaProductHunt />,
  },
  stackoverflow: {
    type: "stackoverflow",
    name: "stackoverflow",
    icon: <FaStackOverflow />,
  },
  twitch: {
    type: "twitch",
    name: "twitch",
    icon: <FaTwitch />,
  },
  whatsapp: {
    type: "whatsapp",
    name: "whatsapp",
    icon: <FaWhatsapp />,
  },
  vimeo: {
    type: "vimeo",
    name: "vimeo",
    icon: <FaVimeo />,
  },

  patreon: {
    type: "patreon",
    name: "patreon",
    icon: <FaPatreon />,
  },
};

const keys = Object.keys(linkObj).sort((a, b) => a.localeCompare(b));
const linkList = {};

for (let i = 0; i < keys.length; i++) {
  linkList[keys[i]] = linkObj[keys[i]];
}
linkList.separator = {
  type: "separator",
  name: "separator",
  icon: <FaGripLines />,
};
linkList.custom = {
  type: "custom",
  name: "custom",
  icon: <FaLink />,
};

const mostPopularLinkList = {
  facebook: {
    type: "facebook",
    name: "facebook",
    icon: <FaFacebook />,
  },
  linkedin: {
    type: "linkedin",
    name: "linkedin",
    icon: <FaLinkedin />,
  },
  twitter: {
    type: "twitter",
    name: "twitter",
    icon: <FaTwitter />,
  },
  instagram: {
    type: "instagram",
    name: "instagram",
    icon: <FaInstagram />,
  },
  youtube: {
    type: "youtube",
    name: "youtube",
    icon: <FaYoutube />,
  },
  separator: {
    type: "separator",
    name: "separator",
    icon: <FaGripLines />,
  },
  custom: {
    type: "custom",
    name: "custom",
    icon: <FaLink />,
  },
};

const contentLinkList = {
  "youtube embed": {
    type: "youtube embed",
    name: "embedded youtube",
    category: "video",
    icon: <FaYoutubeSquare />,
  },
  "vimeo embed": {
    type: "vimeo embed",
    name: "embedded vimeo",
    category: "video",
    icon: <FaVimeoSquare />,
  },
  "soundcloud embed": {
    type: "soundcloud embed",
    name: "embedded soundcloud",
    category: "video",
    icon: <FaSoundcloud />,
  },
  "mixcloud embed": {
    type: "mixcloud embed",
    name: "embedded mixcloud",
    category: "video",
    icon: <FaMixcloud />,
  },
  "twitch embed": {
    type: "twitch embed",
    name: "embedded twitch",
    category: "video",
    icon: <FaTwitch />,
  },
};

const videoLinkType = [
  "youtube embed",
  "vimeo embed",
  "soundcloud embed",
  "mixcloud embed",
  "twitch embed",
];

export { linkList, mostPopularLinkList, contentLinkList, videoLinkType };
