import React, { useId } from "react";
import styled from "styled-components";
import { colors } from "./constants";

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const StyledTextarea = styled.textarea`
  border-style: solid;
  border-width: 2px;
  border-radius: 16px;
  border-color: ${colors.gray};
  height: 48px;
  width: 100%;
  transition: border 0.15s ease-in;
  min-height: 150px;
  padding: 16px;

  &:focus {
    outline: none;
    border-color: ${colors.primaryHover};
  }
`;

const InputLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  transform: translateX(16px) translateY(-50%);
  padding: 0 8px;
  font-size: 17px;
  height: 24px;
  font-weight: bold;
  border-radius: 16px;
`;

const InlineError = styled.div`
  color: ${colors.danger};
  font-size: 12px;
  font-weight: bold;
  padding-left: 8px;
  padding-top: 4px;
`;

const Textarea = ({
  name,
  label,
  placeholder,
  inlineError,
  value,
  ...props
}) => {
  const inputId = useId();

  return (
    <InputWrapper>
      <InputLabel htmlFor={props.id || inputId}>{label}</InputLabel>
      <StyledTextarea
        id={props.id || inputId}
        name={name}
        {...props}
        placeholder={placeholder}
        value={value}
      />
      {inlineError && <InlineError>{inlineError}</InlineError>}
    </InputWrapper>
  );
};

export default Textarea;
