import axios from "axios";
import { API_URL } from "../api";
import { getToken } from "../session";

export const bInitProfile = async (name, email, token) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${API_URL}/user/init`,
        { name, email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const bRetrieveProfile = async (name, email, token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/user/get-profile`,
        { name, email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token || getToken(),
          },
        }
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const bSaveProfile = async (profile) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/user/profile`, profile, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const bUploadAvatar = async (image) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("avatar", image);

    axios
      .post(`${API_URL}/user/avatar`, data, {
        headers: {
          Authorization: getToken(),
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const bGetSubscription = async (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/user/subscription`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token || getToken(),
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
