// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCcPWoWrP3OF9yyKWhekwfu_wx3GIiBRLo",
  authDomain: "presenceofme-3c9fc.firebaseapp.com",
  projectId: "presenceofme-3c9fc",
  storageBucket: "presenceofme-3c9fc.appspot.com",
  messagingSenderId: "748542775561",
  appId: "1:748542775561:web:23c297232b36546024c3d8",
  measurementId: "G-N0Y8ZQCCD3",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
