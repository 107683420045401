import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import Row, { FlexRow } from "../../components/layout/Row";
import Col from "../../components/layout/Col";
import Input from "../../components/layout/Input";
import { Centered, InfoBar, Separator } from "../../components/layout/Other";
import Textarea from "../../components/layout/Textarea";
import Button from "../../components/layout/Button";
import { bCheckUrlAvailability, bSaveUrl } from "../../backend/profile/url";
import { setPrettyUrl } from "../../redux/features/profile/profileSlice";
import ColorPicker from "../../components/ColorPicker";
import { colors } from "../../lib/constants";
import { S3_BUCKET_AVATARS_URL } from "../../lib/urls";

const Available = styled.div`
  color: #ffffff;
  background-color: #07bc0c;
  border-radius: 50%;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const NotAvailable = styled(Available)`
  background-color: #e74c3c;
`;

const MobileCentered = styled.div`
  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 16px;
  }
`;

const QrWrapper = styled.div`
  padding: 16px;
  background-color: #f5f6fa;
  border-radius: 16px;
`;

const Share = () => {
  const initialUrl = useSelector((state) => state.profile.info.prettyUrl);
  // const avatarUrl = useSelector((state) => state.profile.settings.avatarUrl);

  const [urlChanged, setUrlChanged] = useState(false);
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(0); // 0 - none, 1 - available, 2 - unavailable
  const [qrFgColor, setQrFgColor] = useState(colors[13]);
  const [qrBgColor, setQrBgColor] = useState(colors[14]);

  const qrRef = useRef();
  const dispatch = useDispatch();

  const embedCode = useMemo(() => {
    return `<iframe src="https://www.presenceof.me/${initialUrl}?embed=true" width="500" height="500" style="border-width: 0"></iframe>`;
  }, [initialUrl]);

  useEffect(() => {
    if (url === initialUrl) {
      setUrlChanged(false);
    }
  }, [url]);

  useEffect(() => {
    setUrl(initialUrl);
  }, [initialUrl]);

  const downloadQrCode = () => {
    const data = qrRef.current?.canvas?.current?.toDataURL("image/png", 1.0);
    const a = document.createElement("a");
    a.href = data;
    a.download = "qr-code.png";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const checkAvailability = () => {
    setIsLoading(true);
    bCheckUrlAvailability(url).then(({ available }) => {
      setIsLoading(false);
      if (available) {
        return setIsAvailable(1);
      }

      setIsAvailable(2);
    });
  };

  const saveUrl = () => {
    setIsLoading(true);
    bSaveUrl(url).then(({ success }) => {
      setIsLoading(false);
      if (success) {
        dispatch(setPrettyUrl(url));
        toast("preferred url saved");
        setUrlChanged(false);
        return setIsAvailable(0);
      }

      setIsAvailable(2);
    });
  };

  const copyUrl = () => {
    copy(`https://presenceof.me/${url}`);
    toast("url copied to clipboard");
  };

  return (
    <>
      <h2>get a shareable url</h2>
      <Separator size={16} />
      <FlexRow gap={16} block>
        <Col flex={2}>
          <Input
            label={"custom url"}
            prefix="https://presenceof.me/"
            value={url}
            onChange={(e) => {
              setIsAvailable(0);
              setUrl(e.target.value);
              setUrlChanged(true);
            }}
          />
        </Col>

        <Col
          flex={1}
          style={{ height: "50px", alignItems: "center", display: "flex" }}
        >
          {!urlChanged && isAvailable === 0 && initialUrl && (
            <Button outline block onClick={copyUrl}>
              copy url
            </Button>
          )}

          {urlChanged && isAvailable === 0 && url && (
            <Button
              style={{ whiteSpace: "nowrap" }}
              status="danger"
              onClick={checkAvailability}
              loading={isLoading}
              noBorder
              block
            >
              check availability
            </Button>
          )}
          {isAvailable === 1 && (
            <Row gap={16}>
              <Available>
                <FaCheck />
              </Available>
              <Button
                style={{ whiteSpace: "nowrap" }}
                status="primary"
                onClick={saveUrl}
                loading={isLoading}
                block
              >
                save url
              </Button>
            </Row>
          )}
          {isAvailable === 2 && (
            <Row gap={16}>
              <NotAvailable>
                <ImCross />
              </NotAvailable>
              <Button
                style={{ whiteSpace: "nowrap" }}
                status="info"
                onClick={() => {}}
                disabled
                block
              >
                url not available
              </Button>
            </Row>
          )}
        </Col>
      </FlexRow>
      <Separator size={32} />
      <h2>embed in your existing page</h2>
      <Separator size={16} />
      {initialUrl && (
        <Textarea label={"html code"} value={embedCode} readOnly />
      )}
      {!initialUrl && (
        <InfoBar>
          to get an embeddable card, please set a custom url above first
        </InfoBar>
      )}
      <Separator size={32} />
      <h2>get a qr code</h2>
      <Separator size={16} />
      <FlexRow gap={16}>
        <Col flex={0}>
          <MobileCentered>
            <QrWrapper>
              <QRCode
                size={256}
                value={`https://www.presenceof.me/${initialUrl}`}
                fgColor={qrFgColor}
                bgColor={qrBgColor}
                eyeRadius={20}
                ref={qrRef}
              />
            </QrWrapper>
            <Separator size={16} />
            <Centered>
              <Button onClick={downloadQrCode} outline>
                download qr code
              </Button>
            </Centered>
          </MobileCentered>
        </Col>
        <Col flex={1}>
          <h3>Foreground color</h3>
          <ColorPicker color={qrFgColor} onSelect={setQrFgColor} />
          <h3>Background color</h3>
          <ColorPicker color={qrBgColor} onSelect={setQrBgColor} />
          <Separator size={16} />
        </Col>
      </FlexRow>
    </>
  );
};

export default Share;
