import { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Col from "../../components/layout/Col";
import Header from "../../components/layout/Header";
import { Centered, Container, Separator } from "../../components/layout/Other";
import Input from "../../components/layout/Input";
import Button from "../../components/layout/Button";
import Row from "../../components/layout/Row";
import { colors as appColors } from "../../components/layout/constants";
import {
  useCreateUserWithEmailAndPassword,
  useSendPasswordResetEmail,
  useSignInWithEmailAndPassword,
  useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import Modal from "../../components/layout/Modal";
import { toast } from "react-toastify";
import Spinner from "../../components/layout/Spinner";
import { setToken } from "../../backend/session";
import { bInitProfile } from "../../backend/profile/profile";
import { useDispatch } from "react-redux";
import { setTokenAndData } from "../../redux/features/user/userSlice";

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: blueviolet;
  padding: 4px;
  cursor: pointer;

  &:hover {
    background-color: blueviolet;
    color: #ffffff;
  }
`;

const LoginRow = styled(Row)`
  height: 100vh;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding: 0 16px;

  @media only screen and (max-width: 900px) {
    border: solid 5px blueviolet;
  }
`;

const LoginBlueCol = styled(Col)`
  //background-color: ${appColors.primary};
  background-image: linear-gradient(45deg, ${appColors.primary}, blueviolet);

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const BigText = styled.div`
  position: absolute;
  color: #ffffff11;
  font-size: 10.8vh;
  font-weight: bold;
  left: -32vh;
  bottom: -6vh;
  transform: rotate(270deg) translateX(+50%);
`;

const NotAuthGlobalStyles = createGlobalStyle`
  body {
    overflow: hidden; 
  }
`;

const Login = ({ isLoading }) => {
  const [isSignUp, setIsSignUp] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [inlineErrors, setInlineErrors] = useState({});

  const [showResetPassword, setShowResetPassword] = useState(false);

  const dispatch = useDispatch();

  const [sendPasswordResetEmail, sending, resetPasswordError] =
    useSendPasswordResetEmail(auth);

  const [
    signInWithGoogle,
    signInWithGoogleUser,
    signInWithGoogleLoading,
    signInWithGoogleError,
  ] = useSignInWithGoogle(auth);

  const [signInWithEmailAndPassword, signInUser, signInLoading, signInError] =
    useSignInWithEmailAndPassword(auth);

  const [
    createUserWithEmailAndPassword,
    createdUser,
    createUserLoading,
    createUserError,
  ] = useCreateUserWithEmailAndPassword(auth);

  const loading =
    isLoading || signInWithGoogleLoading || signInLoading || createUserLoading;

  useEffect(() => {
    setName("");
    setEmail("");
    setPassword("");
    setPassword2("");
  }, [isSignUp]);

  const attemptLogIn = () => {
    const e = {};
    let hasErrors = false;
    if (!email || !email.trim()) {
      e.email = "please enter a valid email address";
      hasErrors = true;
    }

    if (!password || !password.trim() || password.length < 8) {
      e.password = "please enter a valid password (at least 8 characters)";
      hasErrors = true;
    }

    if (hasErrors) {
      setInlineErrors(e);
      return;
    }

    setInlineErrors({});

    signInWithEmailAndPassword(email, password).catch((e) => {
      console.log(e);
    });
  };

  const attemptSignUp = () => {
    const e = {};
    let hasErrors = false;
    if (!email || !email.trim()) {
      e.email = "please enter a valid email address";
      hasErrors = true;
    }

    if (!name || !name.trim()) {
      e.name = "please enter a valid name";
      hasErrors = true;
    }

    if (!password || !password.trim() || password.length < 8) {
      e.password = "please enter a valid password (at least 8 characters)";
      hasErrors = true;
    }

    if (password2 !== password) {
      e.password2 = "the two passwords do not match";
      hasErrors = true;
    }

    if (hasErrors) {
      setInlineErrors(e);
      return;
    }

    setInlineErrors({});

    createUserWithEmailAndPassword(email, password).catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {
    if (createUserError) {
      switch (createUserError.code) {
        case "auth/email-already-in-use":
          toast("a user with the same email already exists");
          break;
        default:
          toast(createUserError.code);
      }
    }

    if (signInError) {
      console.log(signInError.message);
      console.log(JSON.stringify(signInError));
      switch (signInError.code) {
        case "auth/wrong-password":
        case "auth/user-not-found":
          toast("invalid email/password combination");
          break;
        default:
          toast(createUserError.code);
      }
    }
  }, [createUserError, signInError]);

  /*
  useEffect(() => {
    if (createdUser) {
      console.log(createdUser);
      createdUser.user.getIdToken().then((idToken) => {
        console.log(idToken);
        dispatch(
          setTokenAndData({
            token: idToken,
            name,
            email,
          })
        );
      });
    }
    }, [createdUser]);

*/
  /*
  useEffect(() => {
    if (createdUser) {
      createdUser.getIdToken().then((t) => {
        setToken(t);
        bInitProfile(name, email, t).then(() => {
          userInitialized();
        });
      });
    }
  }, [createdUser]);
*/

  return (
    <>
      <LoginRow>
        <LoginBlueCol flex={7}>
          <BigText>presenceof.me</BigText>
        </LoginBlueCol>
        <Col flex={3} style={{ height: "100%" }}>
          {loading && (
            <Centered style={{ height: "100%" }}>
              <Spinner size={72} />
            </Centered>
          )}

          {!loading && (
            <LoginWrapper>
              <NotAuthGlobalStyles />
              <Header logo={<Logo>presenceof.me</Logo>} transparent />
              {!isSignUp && !loading && (
                <Container>
                  <Input
                    label="email address"
                    type="text"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    inlineError={inlineErrors.email}
                  />
                  <Separator size={32} />
                  <Input
                    label="password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    inlineError={inlineErrors.password}
                  />
                  <Separator size={16} />
                  <Button
                    status="text"
                    small
                    onClick={() => {
                      setTempEmail("");
                      setShowResetPassword(true);
                    }}
                  >
                    forgot your password?
                  </Button>
                  <Separator size={32} />
                  <Button status="primary" block onClick={attemptLogIn}>
                    log in
                  </Button>
                  <Separator size={16} />
                  <Button
                    status="primary"
                    outline
                    block
                    onClick={() => signInWithGoogle([])}
                  >
                    log in with google
                  </Button>
                  <Separator size={16} />
                  <Button status="text" block onClick={() => setIsSignUp(true)}>
                    create a new account
                  </Button>
                </Container>
              )}

              {isSignUp && !loading && (
                <Container>
                  <Input
                    label="full name"
                    type="text"
                    value={name}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    inlineError={inlineErrors.name}
                  />
                  <Separator size={32} />
                  <Input
                    label="email address"
                    type="text"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    inlineError={inlineErrors.email}
                  />
                  <Separator size={32} />
                  <Input
                    label="password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    inlineError={inlineErrors.password}
                  />
                  <Separator size={32} />
                  <Input
                    label="repeat password"
                    type="password"
                    name="password2"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    inlineError={inlineErrors.password2}
                  />
                  <Separator size={32} />
                  <Button status="primary" block onClick={attemptSignUp}>
                    sign up
                  </Button>
                  <Separator size={16} />
                  <Button
                    status="primary"
                    outline
                    block
                    onClick={() => signInWithGoogle([])}
                  >
                    sign up with google
                  </Button>
                  <Separator size={16} />
                  <Button
                    status="text"
                    block
                    onClick={() => setIsSignUp(false)}
                  >
                    I already have an account
                  </Button>
                </Container>
              )}
            </LoginWrapper>
          )}
        </Col>
      </LoginRow>

      <Modal
        title="reset password"
        showOverlay
        showCancelButton
        showActionButton
        visible={showResetPassword}
        actionLabel="send reset email"
        onClose={() => setShowResetPassword(false)}
        onAct={() => sendPasswordResetEmail(tempEmail)}
      >
        <Separator size={16} />
        <Input
          label="email address"
          type="text"
          name="email"
          value={tempEmail}
          onChange={(e) => setTempEmail(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default Login;
