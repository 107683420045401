import React from "react";
import styled from "styled-components";
import Row from "./Row";
import Col from "./Col";
import { colors } from "./constants";

const StyledHeader = styled(Row)`
  background-color: ${({ transparent }) =>
    transparent ? "#00000000" : colors.primary};
  padding: 32px 16px;
  height: 50px;
  align-items: center;
  //margin: 8px;
  //border-radius: 8px;

  button {
    white-space: nowrap;
  }
`;

const Header = ({ logo, options, transparent, ...props }) => {
  return (
    <StyledHeader transparent={transparent} {...props}>
      <Col flex={1}>
        <Row>{logo}</Row>
      </Col>
      <Col flex={0}>{options}</Col>
    </StyledHeader>
  );
};

export default Header;
