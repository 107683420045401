import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import Row, { FlexRow } from "../../components/layout/Row";
import Col from "../../components/layout/Col";
import {
  Centered,
  Container,
  InfoBarPrimary,
  RoundContainer,
  RoundGrayContainer,
  Separator,
} from "../../components/layout/Other";
import Button from "../../components/layout/Button";
import { colors } from "../../components/layout/constants";
import Tabs, { StickyTabsWrapper } from "../../components/layout/Tabs";
import moment from "moment";
import {
  bCreatePortalSession,
  bCreateSession,
} from "../../backend/profile/subscription";
import { enableSubscriptions } from "../../lib/flags";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import Login from "../login/Login";
import { Gradient } from "@visx/visx";

const logos = [
  require("./logos/visa@2x.png"),
  require("./logos/mastercard@2x.png"),
  require("./logos/american-express@2x.png"),
  require("./logos/diners.png"),
  require("./logos/UnionPay_logo.png"),
  require("./logos/discover@2x.png"),
  require("./logos/paypal.jpeg"),
];

const Paid = styled.div`
  color: #ffffff;
  background-color: #07bc0c;
  border-radius: 50%;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

const NotPaid = styled(Paid)`
  background-color: #e74c3c;
`;

const Status = styled.div`
  color: #ffffff;
  background-color: #07bc0c;
  border-radius: 12px;
  display: inline-block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 12px;
`;

const OverdueStatus = styled(Status)`
  background-color: #e74c3c;
`;

const tabs = [
  {
    label: "subscription information",
  },
  // {
  //   label: "your invoices",
  // },
];

const TableWrapper = styled.div`
  background-color: #f5f6fa;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 2px;
  border-radius: 8px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2px;
  font-weight: bold;
  font-size: 15px;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2px;
  font-size: 14px;
`;

const HeaderCell = styled.div`
  padding: 4px;
  ${({ flex }) => (flex ? `flex: ${flex};` : "")}
  background-color: #f5f6fa;
`;

const Cell = styled.div`
  padding: 8px 4px;
  ${({ flex }) => (flex ? `flex: ${flex};` : "")}
  background-color: #ffffff;
  display: flex;
  align-items: center;
`;

const FeaturesList = styled.ul`
  list-style-type: none;

  li {
    margin: 8px;
    font-size: 18px;
    color: ${colors.primary};
  }
`;

const Price = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

const TermsLink = styled.a`
  color: ${colors.primary};
  font-weight: bold;
  text-decoration: none;
`;

const CardLogo = styled.img`
  height: 28px;
  border-radius: 4px;
  border: solid 1px #cccccc;
`;

const SmallButtonText = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

const getInvoicePeriod = (invoice) => {
  return (
    moment.unix(invoice.data.data[0].period.start).format("MMMM Do YYYY") +
    " - " +
    moment.unix(invoice.data.data[0].period.end).format("MMMM Do YYYY")
  );
};

const Subscription = () => {
  const subscriptionData = useSelector((state) => state.user.subscription);
  const [user] = useAuthState(auth);

  const [selectedTab, setSelectedTab] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);

  const manageSubscription = () => {
    setIsLoading(true);
    bCreatePortalSession().then((data) => {
      setIsLoading(false);
      if (data && data.url) {
        window.location = data.url;
      }
    });
  };

  const getSubscriptionStatus = useCallback(
    (status) => {
      switch (status) {
        case "ACTIVE":
          return (
            <Container>
              <Status>active - no payment overdue</Status>
            </Container>
          );
        case "OVERDUE":
          return (
            <Container>
              <OverdueStatus>payment overdue</OverdueStatus>
              <div>
                you have a payment that's overdue. please{" "}
                <Button
                  status="primary"
                  outline
                  noBorder
                  small
                  onClick={manageSubscription}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  click here
                </Button>{" "}
                to go to the subscription dashboard and address the overdue
                invoices.
              </div>
            </Container>
          );
        default:
          return (
            <Container>
              <Status>{status}</Status>
            </Container>
          );
      }
    },
    [isLoading]
  );

  // useEffect(() => {
  //   if (!enableSubscriptions || !user) {
  //     return;
  //   }
  //
  // // window.Paddle.Checkout.open({ product: "32461", email: user?.email });
  // window.Paddle.Checkout.open({
  //   method: "inline",
  //   product: 32461, // Replace with your Product or Plan ID
  //   email: user?.email,
  //   allowQuantity: false,
  //   disableLogout: true,
  //   frameTarget: "checkout-container", // The className of your checkout <div>
  //   frameInitialHeight: 416,
  //   frameStyle:
  //     "width:100%; min-width:320px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
  //   passthrough: JSON.stringify({
  //     userId: user.uid,
  //   }),
  // });
  // }, [user]);

  const openCheckout = () => {
    // window.Paddle.Checkout.open({ product: "32461", email: user?.email });
    setTermsAgreed(true);
    window.Paddle.Checkout.open({
      method: "inline",
      product: 32461, // Replace with your Product or Plan ID
      email: user?.email,
      allowQuantity: false,
      disableLogout: true,
      frameTarget: "checkout-container", // The className of your checkout <div>
      frameInitialHeight: 416,
      frameStyle:
        "width:100%; min-width:320px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
      passthrough: JSON.stringify({
        userId: user.uid,
      }),
    });
  };

  if (!enableSubscriptions) {
    return <InfoBarPrimary>404 - page not found</InfoBarPrimary>;
  }

  if (!subscriptionData) {
    return (
      <RoundContainer>
        {/*
        <InfoBarPrimary>
          no subscription attached to your account
        </InfoBarPrimary>
*/}
        <Separator size={20} />
        <FlexRow gap={16}>
          <Col flex={1.5}>
            <RoundGrayContainer>
              <h1>premium features</h1>
              <Separator />
              <FeaturesList>
                <li>unlimited links</li>
                <li>remove presenceof.me branding</li>
                <li>priority support</li>
                <li>priority on suggested features</li>
              </FeaturesList>
              <RoundContainer>
                <Centered>
                  <Price>9.90E / month</Price>
                  <div>cancel anytime</div>
                </Centered>
              </RoundContainer>
            </RoundGrayContainer>
          </Col>
          <Col flex={2.5}>
            <RoundGrayContainer>
              <h1>payment information</h1>
              <Separator size={10} />
              <Row gap={8}>
                {logos.map((l, k) => (
                  <CardLogo src={l} key={k} />
                ))}
              </Row>
              {!termsAgreed && (
                <>
                  <Separator size={10} />
                  In order to proceed to the checkout, you must first agree with
                  the{" "}
                  <TermsLink
                    href="https://presenceof.me/terms-of-use"
                    target="_blank"
                  >
                    Terms of Use
                  </TermsLink>{" "}
                  and the{" "}
                  <TermsLink
                    href="https://presenceof.me/refund-policy"
                    target="_blank"
                  >
                    Refund Policy
                  </TermsLink>
                  .
                  <Separator size={10} />
                </>
              )}
              <div className="checkout-container" />
              {/*<CardForm />*/}
              {!termsAgreed && (
                <>
                  <Separator />
                  <input
                    type="checkbox"
                    id="terms"
                    value={termsChecked}
                    onChange={(e) => setTermsChecked(e.target.checked)}
                  />
                  <label htmlFor="terms">
                    I have read and agree with the Terms of Use and the Refund
                    Policy.
                  </label>
                  <Separator size={32} />
                  <Centered>
                    <Button disabled={!termsChecked} onClick={openCheckout}>
                      <div>proceed to checkout</div>
                      <SmallButtonText>
                        this will just open the checkout form - you will not be
                        charged yet
                      </SmallButtonText>
                    </Button>
                  </Centered>
                </>
              )}
            </RoundGrayContainer>
          </Col>
        </FlexRow>
      </RoundContainer>
    );
  }

  return (
    <>
      <StickyTabsWrapper>
        <Tabs
          selected={selectedTab}
          onSelect={(t) => setSelectedTab(t)}
          tabs={tabs}
        />
      </StickyTabsWrapper>

      <Separator size={8} />

      {selectedTab === 0 && (
        <RoundContainer>
          <Row gap={16} block>
            <Col flex={1}>
              <RoundGrayContainer style={{ height: "100%" }}>
                <Centered style={{ height: "100%" }}>
                  <h2>subscription started at</h2>
                  {moment(subscriptionData.otherInfo.createdAt).format(
                    "MMMM Do YYYY"
                  )}
                </Centered>
              </RoundGrayContainer>
            </Col>
            <Col flex={1}>
              <RoundGrayContainer style={{ height: "100%" }}>
                <Centered style={{ height: "100%" }}>
                  <h2>subscription status</h2>
                  {getSubscriptionStatus(subscriptionData.status)}
                </Centered>
              </RoundGrayContainer>
            </Col>
          </Row>
          <Separator size={32} />
          <h2>your invoices</h2>
          <Separator size={16} />
          <TableWrapper>
            <TableHeader>
              <HeaderCell flex={2}>invoice id</HeaderCell>
              <HeaderCell flex={2}>period</HeaderCell>
              <HeaderCell flex={2}>created at</HeaderCell>
              <HeaderCell flex={1}>paid</HeaderCell>
              <HeaderCell flex={1}></HeaderCell>
            </TableHeader>
            {subscriptionData.invoices.map((i, k) => (
              <TableRow key={i.id}>
                <Cell flex={2}>{k + 1}</Cell>
                <Cell flex={2}>{getInvoicePeriod(i)}</Cell>
                <Cell flex={2}>
                  {moment(i.createdAt).format("MMMM Do YYYY")}
                </Cell>
                <Cell flex={1}>
                  {i.paid ? (
                    <Paid>
                      <FaCheck />
                    </Paid>
                  ) : (
                    <NotPaid>
                      <ImCross />
                    </NotPaid>
                  )}
                </Cell>
                <Cell flex={1}>
                  <a href={i.invoiceUrl} target="_blank">
                    <Button small>preview</Button>
                  </a>
                </Cell>
              </TableRow>
            ))}
          </TableWrapper>

          <Separator size={32} />
          <Centered>
            <Row gap={16}>
              <Button status="info" onClick={manageSubscription}>
                more subscription information
              </Button>
              <Button status="danger" outline onClick={manageSubscription}>
                cancel subscription
              </Button>
            </Row>
          </Centered>
        </RoundContainer>
      )}
    </>
  );
};

export default Subscription;
